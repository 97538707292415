import gql from 'graphql-tag';

export const CREATE_BLACKOUT_MUTATION = gql`
    mutation CreateBlackout(
        $projectId: ID!
        $blackOutCriteriaName: BlackOutType!
        $blackOutCriteriaValue: String!
        $blackOutStart: AWSDateTime!
        $blackOutEnd: AWSDateTime!
    ) {
        createBlackout(input: {
            projectId: $projectId
            blackOutCriteriaName: $blackOutCriteriaName
            blackOutCriteriaValue: $blackOutCriteriaValue
            blackOutStart: $blackOutStart
            blackOutEnd: $blackOutEnd
        }) {
            id
        }
    }
`;

export const UPDATE_BLACKOUT_MUTATION = gql`
    mutation UpdateBlackout(
        $id: ID!
        $blackOutCriteriaValue: String
        $blackOutStart: AWSDateTime
        $blackOutEnd: AWSDateTime
    ) {
        updateBlackout(input: {
            id: $id
            blackOutCriteriaValue: $blackOutCriteriaValue
            blackOutStart: $blackOutStart
            blackOutEnd: $blackOutEnd
        }) {
            id
        }
    }
`;

export const DELETE_BLACKOUT_MUTATION = gql`
    mutation DeleteBlackout(
        $id: ID!
    ) {
        deleteBlackout(input: {
            id: $id
        }) {
            id
        }
    }
`;