import config from '../../config';
import { getToken } from './auth.service';
import { stripSpecialCharacters } from '../../routes/quality-assurance/components/modal/addPhotoModal';

export const getUploadUrl = async (projectId, photoName) => {
  try {
    const token = await getToken();
    let response = await fetch(`${config.getUploadUrlApi}/${projectId}/${photoName}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token
      }
    });
    response = response.json();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const replacePhoto = async (signedUrl, photo) => {
  try {
    const sanitizedPhotoName = stripSpecialCharacters(photo.name);
    const sanitizedPhoto = new File([photo], sanitizedPhotoName, { type: photo.type });
    const formData = new FormData();
    formData.append('file', sanitizedPhoto);
    await fetch(signedUrl, {
      method: 'PUT',
      body: formData
    });
  } catch (error) {
    console.log(error);
  }
}

export const addPhoto = async (photo, workOrderId, oldMeterNumber, photoType, projectId) => {
  try {
    const token = await getToken();
    const formData = new FormData();
    const sanitizedPhotoName = stripSpecialCharacters(photo.name);
    console.log("addPhoto, sanitizedPhotoName", sanitizedPhotoName);
    const sanitizedPhoto = new File([photo], sanitizedPhotoName, { type: photo.type });
    console.log("addPhoto, sanitizedPhoto", sanitizedPhoto.name);
    formData.append('photo', sanitizedPhoto);
    formData.append('workOrderId', workOrderId);
    formData.append('oldMeterNumber', oldMeterNumber);
    formData.append('photoType', photoType);
    formData.append('projectId', projectId);
    formData.append('photoName', sanitizedPhotoName);
    await fetch(config.addPhotoApi, {
      method: 'POST',
      headers: {
        'Authorization': token
      },
      body: formData
    });
  } catch (error) {
    console.log(error);
  }
}

export const getUserAvatar = async (url) => {
  try {
    const token = await getToken();
    let response = await fetch(`${config.getUserAvatar}?key=${url}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token
      }
    });
    response = response.json();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const getImagePreSignedUrl = async (imagesPaths) => {
  try {
    const token = await getToken();
    const response = await fetch(
      `${config.workOrderDetailsImageApi}?imagesPaths=${imagesPaths}`,
      {
        method: 'get',
        headers: new Headers({
          Authorization: token
        })
      }
    );
    const imageUrl = await response.json();
    return imageUrl;
  } catch (error) {
    console.log(error);
  }
};