import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import QualityAssuranceExport from '../button/export';
import { Input } from 'semantic-ui-react';
import { APPROVE_WORKORDER_MUTATION } from '../../../../graphql/mutations/workorder';
import { getWorkOrderDetails } from '../../../../core/services/workOrder.service';
import AppContext from '../../../../core/context/app.context';
import AddPhotoModal from '../modal/addPhotoModal';
import { useTimeZone } from '../../../../helpers/date';
import CommentModal from '../../../../shared/components/modal/comment';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';
import MeterDeployDetailsModal from '../../../../shared/components/workorder-details/meterDeployDetails';
import WaterMeterDeployDetailsModal from '../../../../shared/components/workorder-details/waterMeterDeployDetails';
import WaterDeployDetailsModal from '../../../../shared/components/workorder-details/waterDeployDetails';
import DemoModal from '../../../../shared/components/modal/demoModal';
import { orderBy } from '@progress/kendo-data-query';
import MODAL_TYPES from '../../../../constants/qaModalTypes';

class QualityAssuranceInReview extends Component {

  static contextType = AppContext;
  _isMounted = false;

  state = {
    sort: [],
    selectedWorkOrder: {},
    showMeterDeployDetails: false,
    showWaterDeployDetails: false,
    showWaterMeterDeployDetails: false,
    selectedWorkOrderIndex: 0,
    modalDetailsLoading: false,
    showCommentModal: false,
    modalType: '',
    workOrders: [],
    workOrdersLength: 0,
    photoIndex: 0,
    detailsOpenCount: 0
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  componentWillMount = () => {
    this._isMounted = true;
    const { workOrders, photoType, showAddModal, uploadingNewPhoto } = this.props;
    this.setState({ workOrders, photoType, showAddModal, uploadingNewPhoto });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps = (newProps) => {
    const { workOrders, photoType, showAddModal, uploadingNewPhoto } = newProps;
    this.setState({ workOrders, photoType, showAddModal, uploadingNewPhoto });
  }

  setDetailsOpenCount = (detailsOpenCount) => {
    this.setState({ detailsOpenCount });
  }

  rowClick = async event => {
    const { workOrders } = this.state;
    const selectedWorkOrderIndex = workOrders.findIndex(workOrder => workOrder === event.dataItem);
    const selectedWorkOrder = workOrders[selectedWorkOrderIndex];
    this.getWorkOrderDetails(selectedWorkOrder.id);
    let newState = {};
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { showMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { showWaterMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { showWaterDeployDetails: true };
    }
    this.setState({ ...newState, selectedWorkOrderIndex });
  }

  handleSearch = (event, data) => {
    const { onSearch } = this.props;
    const searchBy = data.value.toLowerCase();
    onSearch(searchBy);
  }

  closeDetails = () => {
    const {
      getWorkOrdersWithStatusEscalated,
      getWorkOrdersWithStatusCompleted,
      getWorkOrdersWithStatusInReview,
    } = this.props;
    const { refreshTabs, selectedWorkOrder } = this.state;
    let newState = { photoIndex: 0, detailsOpenCount: 0 };
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { ...newState, showMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { ...newState, showWaterMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { ...newState, showWaterDeployDetails: false };
    }
    if (refreshTabs) {
      this.setState({ ...newState, workOrders: [], refreshTabs: false });
      getWorkOrdersWithStatusInReview();
      getWorkOrdersWithStatusEscalated();
      getWorkOrdersWithStatusCompleted();
    } else {
      this.setState(newState);
    }
  }

  approve = workOrderId => {
    const { updateWorkOrderStatus } = this.props;
    updateWorkOrderStatus({ query: APPROVE_WORKORDER_MUTATION, workOrderId: workOrderId || null });
  }

  approveWithPromise = async workOrderId => {
    const { updateWorkOrderStatus, getWorkOrdersWithStatusInReview, getWorkOrdersWithStatusCompleted } = this.props;
    await updateWorkOrderStatus({ query: APPROVE_WORKORDER_MUTATION, workOrderId: workOrderId || null });
    getWorkOrdersWithStatusInReview();
    getWorkOrdersWithStatusCompleted();
  }

  onReject = () => {
    this.setState({ showCommentModal: true, modalType: MODAL_TYPES.REJECT });
  }

  onApprove = (workOrderId) => {
    const { selectedWorkOrder } = this.state;
    let newState = {};
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { showMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { showWaterMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { showWaterDeployDetails: false };
    }
    this.setState(newState);
    this.approveWithPromise(workOrderId);
  }

  onEscalate = () => {
    this.setState({ showCommentModal: true, modalType: MODAL_TYPES.ESCALATED });
  }

  handleApproveAndNext = async workOrderId => {
    this.setState({ refreshTabs: true });
    this.approve(workOrderId);
    let { selectedWorkOrderIndex, workOrders } = this.state;
    workOrders.splice(selectedWorkOrderIndex, 1);
    let nextWorkOrder = null;
    if (workOrders.length - 1 < selectedWorkOrderIndex) {
      nextWorkOrder = workOrders[0];
      selectedWorkOrderIndex = 0;
    } else {
      nextWorkOrder = workOrders[selectedWorkOrderIndex];
    }
    await this.getWorkOrderDetails(nextWorkOrder.id);
    this.setState({ selectedWorkOrderIndex, workOrders });
  }

  onNext = async () => {
    let { selectedWorkOrderIndex, workOrders } = this.state;
    let nextWorkOrder = null;
    if (workOrders.length - 1 < selectedWorkOrderIndex + 1) {
      nextWorkOrder = workOrders[0];
      selectedWorkOrderIndex = 0;
    } else {
      selectedWorkOrderIndex = selectedWorkOrderIndex + 1;
      nextWorkOrder = workOrders[selectedWorkOrderIndex];
    }
    await this.getWorkOrderDetails(nextWorkOrder.id);
    this.setState({ selectedWorkOrderIndex, workOrders });
  }

  getWorkOrderDetails = async (id) => {
    const { currentProject } = this.context;
    const { formatWorkOrderDetailsPhotos } = this.props;
    this.setState({ modalDetailsLoading: true });
    let workOrder = await getWorkOrderDetails(id);
    workOrder = await formatWorkOrderDetailsPhotos(workOrder);
    workOrder = useTimeZone(workOrder, ['workOrderLastVisitDate', 'workOrderAuditDate'], currentProject.projectTimeZone);
    if (workOrder.photos && workOrder.photos.length > 0) {
      workOrder.photos = useTimeZone(workOrder.photos, ['photoDate'], currentProject.projectTimeZone);
    }
    if (workOrder.workOrderComments && workOrder.workOrderComments.items && workOrder.workOrderComments.items.length > 0) {
      workOrder.workOrderComments.items = useTimeZone(workOrder.workOrderComments.items, ['contentDate'], currentProject.projectTimeZone);
    }
    if (workOrder.workOrderHistorys && workOrder.workOrderHistorys.items && workOrder.workOrderHistorys.items.length > 0) {
      workOrder.workOrderHistorys.items = useTimeZone(workOrder.workOrderHistorys.items, ['historyDate'], currentProject.projectTimeZone);
    }
    this.setState({ modalDetailsLoading: false, selectedWorkOrder: workOrder });
  }

  onCommentSubmit = async (comment) => {
    const { selectedWorkOrder, modalType, } = this.state;
    const {
      reject,
      escalate,
      getWorkOrdersWithStatusEscalated,
      getWorkOrdersWithStatusInReview,
    } = this.props;
    if (modalType === MODAL_TYPES.REJECT) {
      await reject(selectedWorkOrder.id, comment);
      getWorkOrdersWithStatusInReview();
      this.closeDetails();
    } else if (modalType === MODAL_TYPES.ESCALATED) {
      await escalate(selectedWorkOrder.id, comment);
      getWorkOrdersWithStatusEscalated();
      getWorkOrdersWithStatusInReview();
      this.closeDetails();
    } else {
      this.getWorkOrderDetails(selectedWorkOrder.id);
    }
  }

  setModalDetailsLoading = (modalDetailsLoading) => {
    this.setState({ modalDetailsLoading });
  }

  onCloseCommentModal = () => {
    this.setState({ showCommentModal: false, modalType: '' });
  }

  onGallerySlide = (photoIndex) => {
    this.setState({ photoIndex });
  }

  addPhoto = async (event, workOrderId, oldMeterNumber) => {
    const { addPhoto } = this.props;
    await addPhoto(event, workOrderId, oldMeterNumber);
    this.getWorkOrderDetails(workOrderId);
  }

  replacePhoto = async (event) => {
    const { currentProject } = this.context;
    if (currentProject.isDemo) {

    } else {

    }
    this.setState({ modalDetailsLoading: true });
    const { replacePhoto } = this.props;
    const { selectedWorkOrder, photoIndex } = this.state;
    await replacePhoto(event, selectedWorkOrder, photoIndex);
    this.getWorkOrderDetails(selectedWorkOrder.id);
  }

  onSortChange = (e) => {
    const { workOrders } = this.state;
    const results = orderBy(workOrders, e.sort);
    this.setState({
      sort: e.sort,
      workOrders: results
    });
  }

  render() {
    const {
      showMeterDeployDetails,
      showWaterMeterDeployDetails,
      selectedWorkOrder,
      modalDetailsLoading,
      showCommentModal,
      photoIndex,
      showWaterDeployDetails,
      detailsOpenCount,
      modalType
    } = this.state;
    const {
      inReviewLoading,
      showAddPhotoModal,
      onPhotoTypeChange,
      closeAddPhotoModal,
      photoType,
      showAddModal,
      uploadingNewPhoto,
      replaceFileInputRef,
      addFileInputRef,
      user,
      showCustomInputText,
      onCustomTypeChange,
      customPhotoType,
      getWorkOrdersWithStatusInReview,
      qualityAssuranceHeaderLabelColor,
      qualityAssuranceType,
      isQualityAssurance,
      showDemoModal,
      setShowDemoModal,
      onReplacePhotoClick,
      verifyWorkOrder,
      workOrdersVerified
    } = this.props;
    const { currentProject } = this.context;
    const oldMeterReadingHighThreshold = currentProject.projectConfiguration.oldMeterReadingHighThreshold || null;
    let workOrders = inReviewLoading ? [] : this.state.workOrders;
    return (
      <div className='quality-assurance-in-review'>
        <div className='quality-assurance-tool-bar'>
          <QualityAssuranceExport workOrders={workOrders} />
          {showDemoModal && <DemoModal showModal={showDemoModal} closeModal={() => setShowDemoModal(false)} />}
          {showAddModal && <AddPhotoModal
            customPhotoType={customPhotoType}
            onCustomTypeChange={onCustomTypeChange}
            showModal={showAddModal}
            closeModal={closeAddPhotoModal}
            uploadingNewPhoto={uploadingNewPhoto}
            onSubmit={this.addPhoto}
            photoType={photoType}
            onPhotoTypeChange={onPhotoTypeChange}
            fileInputRef={addFileInputRef}
            selectedWorkOrder={selectedWorkOrder}
            showCustomInputText={showCustomInputText} />}
          {showCommentModal && <CommentModal
            modalType={modalType}
            submitWithoutComment={modalType === MODAL_TYPES.REJECT}
            showEscalationCategories={true}
            workOrderId={selectedWorkOrder.id}
            userId={user.id}
            showModal={showCommentModal}
            onSubmit={this.onCommentSubmit}
            closeModal={this.onCloseCommentModal} />}
          {showWaterDeployDetails && <WaterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            onReplacePhotoClick={onReplacePhotoClick}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            currentProject={currentProject}
            getWorkOrdersWithStatusInReview={getWorkOrdersWithStatusInReview}
            getWorkOrderDetails={this.getWorkOrderDetails}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            showModal={showWaterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            handleApprove={this.onApprove}
            handleEscalate={this.onEscalate}
            handleApproveAndNext={this.handleApproveAndNext}
            handleReject={this.onReject}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onNext={this.onNext}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            fileInputRef={replaceFileInputRef}
            replacePhoto={this.replacePhoto}
            showAddPhotoModal={showAddPhotoModal}
            workOrdersVerified={workOrdersVerified}
            verifyWorkOrder={verifyWorkOrder} />}
          {showWaterMeterDeployDetails && <WaterMeterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            onReplacePhotoClick={onReplacePhotoClick}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            getWorkOrdersWithStatusInReview={getWorkOrdersWithStatusInReview}
            getWorkOrderDetails={this.getWorkOrderDetails}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            currentProject={currentProject}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            showModal={showWaterMeterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            handleApprove={this.onApprove}
            handleEscalate={this.onEscalate}
            handleApproveAndNext={this.handleApproveAndNext}
            handleReject={this.onReject}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onNext={this.onNext}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            fileInputRef={replaceFileInputRef}
            replacePhoto={this.replacePhoto}
            showAddPhotoModal={showAddPhotoModal}
            workOrdersVerified={workOrdersVerified}
            verifyWorkOrder={verifyWorkOrder} />}
          {showMeterDeployDetails && <MeterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            onReplacePhotoClick={onReplacePhotoClick}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            getWorkOrdersWithStatusInReview={getWorkOrdersWithStatusInReview}
            getWorkOrderDetails={this.getWorkOrderDetails}
            currentProject={currentProject}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            fileInputRef={replaceFileInputRef}
            replacePhoto={this.replacePhoto}
            showAddPhotoModal={showAddPhotoModal}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            handleReject={this.onReject}
            handleApproveAndNext={this.handleApproveAndNext}
            handleEscalate={this.onEscalate}
            handleApprove={this.onApprove}
            showModal={showMeterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onNext={this.onNext}
            workOrdersVerified={workOrdersVerified}
            verifyWorkOrder={verifyWorkOrder} />}
          <Input placeholder='Search...' icon='search' fluid style={{ flex: 1 }} onChange={this.handleSearch} />
        </div>
        <Grid
          className='quality-assurance-in-review-grid'
          sortable={{
            mode: 'single'
          }}
          sort={this.state.sort}
          onSortChange={this.onSortChange}
          style={{ height: '90%' }}
          data={workOrders}
          onRowClick={this.rowClick}>
          <Column field='oldMeterNumber' title='Old Meter' width='140px' />
          <Column field='meterStatus' title='Meter Status' width='120px' />
          <Column field='workOrderLastVisitDate' sortable={false} title='Last Visit' width='160px' />
          <Column field='workOrderResourceUser' title='Technician' width='200px' />
          <Column field='districtCode' title='District' width='120px' />
          <Column field='newMeterNumber' title='New Meter' width='160px' />
          <Column field='newMeterAssetNumber' title='Asset' width='160px' />
          <Column field='newRadioNumber' title='New Radio' width='160px' />
          <Column field='meterReadNotes' title='Read Notes' width='200px' />
          <Column field='workOrderAttemptNumber' title='Attempt' width='120px' />
          <Column field='workOrderFlaggedAsCNC' title='CNC' width='120px' />
          <Column field='workOrderFlaggedAs3strike' title='3 Strike' width='120px' />
          <Column field='workOrderFlaggedAsDamaged' title='Damaged' width='130px' />
          <Column field='workOrderFlaggedAsAdHoc' title='Ad Hoc' width='120px' />
          <Column field='workOrderIsInBlackOut' title='Blackout' width='140px' />
        </Grid>
      </div>
    );
  }
}

export default QualityAssuranceInReview;