import React from 'react';
import { EditBlackoutsModal } from './edit-blackouts-modal.js';
import { Scheduler, MonthView, WeekView, DayView } from '@progress/kendo-react-scheduler';
import { Button } from '@progress/kendo-react-buttons';
import AppContext from '../../../../core/context/app.context';

class BlackOuts extends React.PureComponent {
  static contextType = AppContext;

  state = {
    showEditBlackoutsModal: false,
  }

  componentDidMount() {
    if(document.getElementById('blackouts-scheduler')){
      const schedulerToolbar = document.getElementById('blackouts-scheduler').firstChild;
      const modalButton = document.getElementById('blackouts-modal-toggle');
      if (schedulerToolbar && modalButton) {
        const toolbarArray = Array.from(schedulerToolbar.children);
        toolbarArray.splice(3, 0, modalButton.cloneNode(true));
        while (schedulerToolbar.firstChild) {
          schedulerToolbar.removeChild(schedulerToolbar.firstChild);
        }
        toolbarArray.forEach(el => {
          if (el instanceof Node) {
            schedulerToolbar.appendChild(el.cloneNode(true));
          }
        });
      }
    }
    if (this.props.hasOwnProperty('setBlackoutsSchedulerLoading') && this.props.hasOwnProperty('scrollToRef')) {
      this.props.setBlackoutsSchedulerLoading(false);
      setTimeout(() => {
        this.props.scrollToRef();
      }, 0);
    }
  }

  componentWillUnmount() {
    if (this.props.hasOwnProperty('setBlackoutsSchedulerLoading') && this.props.hasOwnProperty('scrollToRef')) {
      this.props.setBlackoutsSchedulerLoading(false);
      setTimeout(() => {
        this.props.scrollToRef();
      }, 0);
    }
  }

  toggleModal(boolean) {
    this.setState({ showEditBlackoutsModal: boolean });
  }

  getSchedulerData() {
    return this.props.blackouts.map(dataItem => (
      {
        id: dataItem.id,
        start: new Date(new Date(dataItem.blackOutStart).setDate(new Date(dataItem.blackOutStart).getDate() + 1)),
        end: (dataItem.blackOutEnd === dataItem.blackOutStart) ? new Date(new Date(dataItem.blackOutEnd).setTime(new Date(dataItem.blackOutEnd).getTime() + 86500000)) : new Date(new Date(dataItem.blackOutEnd).setDate(new Date(dataItem.blackOutEnd).getDate() + 1)),
        title: dataItem.blackOutCriteriaValue,
        isAllDay: true
      }
    ))
  }

  render() {
    const { currentProject, showEditBlackoutsModal, setShowEditBlackoutsModal } = this.context;
    const { projectTimeZone = '' } = currentProject;
    const data = this.getSchedulerData();
    return (
      <>
        <Scheduler id={'blackouts-scheduler'} timeZone={projectTimeZone} data={data}>
          {this.props.children}
        </Scheduler>
        { showEditBlackoutsModal && <EditBlackoutsModal closeModal={() => setShowEditBlackoutsModal(false)} /> }
      </>
      
    )
  }
}

export const BlackOutsMonthView = React.memo(({ blackouts }) => (
  <BlackOuts blackouts={blackouts}>
    <MonthView workDayStart={"07:00"} workDayEnd={"19:00"} />
    <DayView workDayStart={"07:00"} workDayEnd={"19:00"} />
  </BlackOuts>
));

export const BlackOutsWeekView = React.memo(({ blackouts, setBlackoutsSchedulerLoading, scrollToRef }) => (
  <BlackOuts blackouts={blackouts} setBlackoutsSchedulerLoading={setBlackoutsSchedulerLoading} scrollToRef={scrollToRef}>
    <WeekView workDayStart={"07:00"} workDayEnd={"19:00"} />
  </BlackOuts>
));
