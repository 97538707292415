export const MAP_LAYOUT_TYPES = {
  WorkOrderStatus: 'Work Order Status',
  WorkOrderStatusPOI: 'Work Order Status w/POI',
  CyclePOI: 'Cycle w/POI',
  Route: 'Route',
  Substation: 'Substation',
  Cycle: 'Cycle',
  Circuit: 'Circuit',
  District: 'District',
  Assignment: 'Assignment',
  ProjectStatusOverview: 'Project Status Overview',
  FormSize: 'Form/Size',
  CustomDataFields: 'Custom View'
}

export const MAP_LAYOUT_IDS = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Tertiary: 'Tertiary',
  Overlay: 'Overlay',
  PrimaryCluster: 'PrimaryCluster',
  PrimaryUnclusteredPoint: 'PrimaryUnclusteredPoint',
  PrimaryClusterText: 'PrimaryClusterText',
  Custom: 'Custom',
  CustomCluster: 'CustomCluster',
  CustomUnclusteredPoint: 'CustomUnclusteredPoint',
  CustomClusterText: 'CustomClusterText',
  MultiPolygonOverlay: 'MultiPolygonOverlay',
  MultiPolygonOverlayLabel: 'MultiPolygonOverlayLabel',
}