import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import QualityAssuranceExport from '../button/export';
import { Input } from 'semantic-ui-react';
import CommentModal from '../../../../shared/components/modal/comment';
import { getWorkOrderDetails } from '../../../../core/services/workOrder.service';
import { useTimeZone } from '../../../../helpers/date';
import AppContext from '../../../../core/context/app.context';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';
import MeterDeployDetailsModal from '../../../../shared/components/workorder-details/meterDeployDetails';
import WaterMeterDeployDetailsModal from '../../../../shared/components/workorder-details/waterMeterDeployDetails';
import WaterDeployDetailsModal from '../../../../shared/components/workorder-details/waterDeployDetails';
import { orderBy } from '@progress/kendo-data-query';
import MODAL_TYPES from '../../../../constants/qaModalTypes';

class QualityAssuranceCompleted extends Component {

  static contextType = AppContext;
  _isMounted = false;

  state = {
    selectedWorkOrder: {},
    showMeterDeployDetails: false,
    showWaterDeployDetails: false,
    showWaterMeterDeployDetails: false,
    showCommentModal: false,
    modalType: '',
    clickedEscalate: false,
    modalDetailsLoading: false,
    selectedWorkOrderIndex: 0,
    workOrders: [],
    photoIndex: 0,
    detailsOpenCount: 0,
    sort: []
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  componentWillMount = () => {
    this._isMounted = true;
    const { workOrders } = this.props;
    this.setState({ workOrders });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps = (newProps) => {
    const { workOrders } = newProps;
    this.setState({ workOrders });
  }

  setDetailsOpenCount = (detailsOpenCount) => {
    this.setState({ detailsOpenCount });
  }

  onGallerySlide = (photoIndex) => {
    this.setState({ photoIndex });
  }

  rowClick = event => {
    const { workOrders } = this.state;
    const selectedWorkOrderIndex = workOrders.findIndex(workOrder => workOrder === event.dataItem);
    const selectedWorkOrder = workOrders[selectedWorkOrderIndex];
    this.getWorkOrderDetails(selectedWorkOrder.id);
    let newState = {};
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { showMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { showWaterMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { showWaterDeployDetails: true };
    }
    this.setState({ ...newState, selectedWorkOrderIndex });
  }

  handleSearch = (event, data) => {
    const { onSearch } = this.props;
    const searchBy = data.value.toLowerCase();
    onSearch(searchBy);
  }

  closeDetails = () => {
    const { selectedWorkOrder } = this.state;
    let newState = { photoIndex: 0, detailsOpenCount: 0 };
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { ...newState, showMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { ...newState, showWaterMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { ...newState, showWaterDeployDetails: false };
    }
    this.setState(newState);
  }

  onEscalate = () => {
    this.setState({ showCommentModal: true, modalType: MODAL_TYPES.ESCALATED });
  }

  getWorkOrderDetails = async (id) => {
    const { currentProject } = this.context;
    if (currentProject) {
      const { formatWorkOrderDetailsPhotos } = this.props;
      this.setState({ modalDetailsLoading: true });
      let workOrder = await getWorkOrderDetails(id);
      workOrder = await formatWorkOrderDetailsPhotos(workOrder);
      workOrder = useTimeZone(workOrder, ['workOrderLastVisitDate', 'workOrderAuditDate'], currentProject.projectTimeZone);
      console.log(workOrder)
      if (workOrder.photos && workOrder.photos.length > 0) {
        workOrder.photos = useTimeZone(workOrder.photos, ['photoDate'], currentProject.projectTimeZone);
      }
      if (workOrder.workOrderComments && workOrder.workOrderComments.items && workOrder.workOrderComments.items.length > 0) {
        workOrder.workOrderComments.items = useTimeZone(workOrder.workOrderComments.items, ['contentDate'], currentProject.projectTimeZone);
      }
      if (workOrder.workOrderHistorys && workOrder.workOrderHistorys.items && workOrder.workOrderHistorys.items.length > 0) {
        workOrder.workOrderHistorys.items = useTimeZone(workOrder.workOrderHistorys.items, ['historyDate'], currentProject.projectTimeZone);
      }
      this.setState({ modalDetailsLoading: false, selectedWorkOrder: workOrder });
    }
  }

  onCloseCommentModal = () => {
    this.setState({ showCommentModal: false, modalType: '' });
  }

  onCommentSubmit = async (comment) => {
    const { selectedWorkOrder, modalType } = this.state;
    const {
      escalate,
      getWorkOrdersWithStatusEscalated,
      getWorkOrdersWithStatusCompleted,
      reject
    } = this.props;
    if (modalType === MODAL_TYPES.REJECT) {
      await reject(selectedWorkOrder.id, comment);
      getWorkOrdersWithStatusCompleted();
      this.closeDetails();
    } else if (modalType === MODAL_TYPES.ESCALATED) {
      this.setState({ clickedEscalate: true });
      await escalate(selectedWorkOrder.id, comment);
      getWorkOrdersWithStatusEscalated();
      getWorkOrdersWithStatusCompleted();
      this.closeDetails();
    } else {
      this.getWorkOrderDetails(selectedWorkOrder.id);
    }
  }

  onNext = async () => {
    let { selectedWorkOrderIndex, workOrders } = this.state;
    let nextWorkOrder = null;
    if (workOrders.length - 1 < selectedWorkOrderIndex + 1) {
      nextWorkOrder = workOrders[0];
      selectedWorkOrderIndex = 0;
    } else {
      selectedWorkOrderIndex = selectedWorkOrderIndex + 1;
      nextWorkOrder = workOrders[selectedWorkOrderIndex];
    }
    await this.getWorkOrderDetails(nextWorkOrder.id);
    this.setState({ selectedWorkOrderIndex, workOrders });
  }

  onReject = () => {
    this.setState({ showCommentModal: true, modalType: MODAL_TYPES.REJECT });
  }

  setModalDetailsLoading = (modalDetailsLoading) => {
    this.setState({ modalDetailsLoading });
  }

  onSortChange = (e) => {
    const { workOrders } = this.state;
    const results = orderBy(workOrders, e.sort);
    this.setState({
      sort: e.sort,
      workOrders: results
    });
  }

  render() {
    const {
      user,
      qualityAssuranceType,
      isQualityAssurance,
      qualityAssuranceHeaderLabelColor,
      getWorkOrdersWithStatusCompleted,
      getWorkOrdersWithStatusEscalated,
    } = this.props;
    const {
      showMeterDeployDetails,
      showWaterMeterDeployDetails,
      selectedWorkOrder,
      modalDetailsLoading,
      showCommentModal,
      workOrders,
      photoIndex,
      showWaterDeployDetails,
      detailsOpenCount,
      modalType
    } = this.state;
    const { currentProject } = this.context;
    const oldMeterReadingHighThreshold = currentProject.projectConfiguration.oldMeterReadingHighThreshold || null;
    return (
      <div className='quality-assurance-completed'>
        <div className='quality-assurance-tool-bar'>
          <QualityAssuranceExport workOrders={workOrders} />
          {showCommentModal && <CommentModal
            modalType={modalType}
            submitWithoutComment={modalType === MODAL_TYPES.REJECT}
            showEscalationCategories={true}
            workOrderId={selectedWorkOrder.id}
            userId={user.id}
            closeModal={this.onCloseCommentModal}
            showModal={showCommentModal}
            onSubmit={this.onCommentSubmit} />}
          {showWaterDeployDetails && <WaterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            currentProject={currentProject}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            getWorkOrdersWithStatusCompleted={getWorkOrdersWithStatusCompleted}
            getWorkOrdersWithStatusEscalated={getWorkOrdersWithStatusEscalated}
            getWorkOrderDetails={this.getWorkOrderDetails}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            user={user}
            showModal={showWaterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            handleEscalate={this.onEscalate}
            modalDetailsLoading={modalDetailsLoading}
            onNext={this.onNext}
            handleReject={this.onReject}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
          />}
          {showWaterMeterDeployDetails && <WaterMeterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            currentProject={currentProject}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            getWorkOrdersWithStatusCompleted={getWorkOrdersWithStatusCompleted}
            getWorkOrdersWithStatusEscalated={getWorkOrdersWithStatusEscalated}
            getWorkOrderDetails={this.getWorkOrderDetails}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            user={user}
            showModal={showWaterMeterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            handleEscalate={this.onEscalate}
            modalDetailsLoading={modalDetailsLoading}
            onNext={this.onNext}
            handleReject={this.onReject}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
          />}
          {showMeterDeployDetails && <MeterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            currentProject={currentProject}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            getWorkOrdersWithStatusCompleted={getWorkOrdersWithStatusCompleted}
            getWorkOrdersWithStatusEscalated={getWorkOrdersWithStatusEscalated}
            getWorkOrderDetails={this.getWorkOrderDetails}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            handleReject={this.onReject}
            handleEscalate={this.onEscalate}
            showModal={showMeterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onNext={this.onNext} />}
          <Input placeholder='Search...' icon='search' fluid style={{ flex: 1 }} onChange={this.handleSearch} />
        </div>
        <Grid
          className='quality-assurance-completed-grid'
          style={{ height: '90%' }}
          data={workOrders}
          sortable={{
            mode: 'single'
          }}
          sort={this.state.sort}
          onSortChange={this.onSortChange}
          onRowClick={this.rowClick}>
          <Column field='oldMeterNumber' title='Old Meter' width='140px' />
          <Column field='meterStatus' title='Meter Status' width='120px' />
          <Column field='workOrderLastVisitDate' sortable={false} title='Last Visit' width='160px' />
          <Column field='workOrderResourceUser' title='Technician' width='200px' />
          <Column field='districtCode' title='District' width='120px' />
          <Column field='newMeterNumber' title='New Meter' width='160px' />
          <Column field='newMeterAssetNumber' title='Asset' width='160px' />
          <Column field='newRadioNumber' title='New Radio' width='160px' />
          <Column field='meterReadNotes' title='Read Notes' width='200px' />
          <Column field='workOrderAttemptNumber' title='Attempt' width='120px' />
          <Column field='workOrderFlaggedAsCNC' title='CNC' width='120px' />
          <Column field='workOrderFlaggedAs3strike' title='3 Strike' width='120px' />
          <Column field='workOrderFlaggedAsDamaged' title='Damaged' width='130px' />
          <Column field='workOrderFlaggedAsAdHoc' title='Ad Hoc' width='120px' />
          <Column field='workOrderIsInBlackOut' title='Blackout' width='140px' />
        </Grid>
      </div>
    );
  }
}

export default QualityAssuranceCompleted;