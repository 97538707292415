import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Icon } from 'semantic-ui-react';import ProjectRouteWrapper from '../../hoc/projectRouteWrapper'
import TopMenu from '../../shared/components/top-menu';
import AppContext from '../../core/context/app.context';
import { Switch } from "@progress/kendo-react-inputs";
import { Scheduler as KendoScheduler, TimelineView, DayView, WeekView, MonthView, AgendaView, DateHeaderCell } from '@progress/kendo-react-scheduler';
import { EditItemWithDynamicTitle } from './scheduler-customization/scheduler-slots/custom-item';
import { CustomSlot } from './scheduler-customization/scheduler-slots/custom-slot';
import { WorkOrdersAssignmentsModalScheduler } from './scheduler-customization/modals/assignmentsScheduler';
import FormWithCustomEditor from '../../shared/components/scheduler/custom-form';
import CUSTOM_MODEL_FIELDS from './scheduler-customization/custom-model';
import { connect } from 'react-redux';
import OverrideAppointmentDialog from './scheduler-customization/modals/override-appointment-dialog';
import * as schedulerActions from '../../core/redux/actions/scheduler.actions';
import Loader from '../../shared/components/loader';
import '@progress/kendo-date-math/tz/all';
import { isEnabled } from '../../core/services/auth.service';
import { listProjectUserByWorkOrderStatusAndCount } from '../../core/services/user.service';
import ROLES from '../../constants/roles';
import { Redirect } from 'react-router-dom';
import routeMaps from '../../core/route-maps/route-maps';
import NotificationGroup from './scheduler-customization/notification-group';
import { ASSIGN_WORKORDERS_MUTATION, UNASSIGN_WORKORDERS_MUTATION } from '../../graphql/mutations/workorder';
import client from '../../core/apollo';

import CustomSchedulerForm from './scheduler-customization/form-components/customSchedulerForm';
import { WorkOrdersWithSharedAddress } from './scheduler-customization/modals/sharedAddressWorkOrders';
import { ConfirmDeleteAppointmentDialog } from './scheduler-customization/modals/confirm-delete-dialog';
import { ConfirmAppointmentHoursDialog } from './scheduler-customization/modals/confirm-hours-dialog';
import { CustomDateHeaderForWeekView, CustomTimeHeaderForWeekAndDayView, CustomDateHeaderForDayView, CustomDateHeaderForMonthView } from './scheduler-customization/cell-headers';

import { CapacityModal } from './scheduler-customization/modals/capacity-modal';

import './styles.css';



const AppointmentRoute = ({
  setShowAppointmentAlreadyExistModal,
  showAppointmentAlreadyExistModal,
  listDuplicateLocationsForWorkOrders,
  listAppointmentsByProject,
  schedulerData,
  deleteAppointment,
  createAppointment,
  updateAppointment,
  setNewAppointment,
  loading,
  setSchedulerData,
  setError,
}) => {
  const context = useContext(AppContext);
  const { user, currentProject } = context;

  // Check if user has permission to manage appointments
  if (!isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin, ROLES.ClientAdmin])) {
    return <Redirect to={routeMaps.dashboard} />;
  }

  // Check if appointment management is enabled for the project
  if (!currentProject || !currentProject.projectConfiguration || 
      !currentProject.projectConfiguration.additionalFeatures || 
      !currentProject.projectConfiguration.additionalFeatures.appointmentManagement) {
    return <Redirect to={routeMaps.dashboard} />;
  }

  const [sharedWorkOrderAddressModal, setSharedWorkOrderAddressModal] = useState(false);
  const [filterDuplicateAddresses, setFilterDuplicateAddresses] = useState(false);
  const schedulerRef = useRef(null)
  // used to preserve the form state in case the Confirm Hours modal is needed.
  const [appointmentForm, setAppointmentForm] = useState({
    appointment: null,
    type: null,
  })
  const [data, setData] = useState([]);
  const [appointmentStartDateWarning, setAppointmentStartDateWarning] = useState(false);
  const [schedulerState, setSchedulerState] = useState({
    view: 'week',
    date: new Date(),
    startDate: null,
    endDate: null,
  });
  const [capacityModal, setCapacityModal] = useState(false);
  const [assignmentModal, setAssignmentModal] = useState(false);
  const [schedulerFormModal, setSchedulerFormModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [appointmentHoursModal, setAppointmentHoursModal] = useState(false);
  const [technicianUsers, setTechnicianUsers] = useState([]);
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [assignmentsList, setAssignmentsList] = useState({
    assigned: [],
    unassigned: [],
  });

  // useEffect(() => {
  //   // hide the 'all day' row in scheduler
  //   if(schedulerRef.current){
  //     if(schedulerState.view === 'week' || schedulerState.view === 'day') schedulerRef.current.element.children[1].children[0].children[1].style.display = 'none';
  //   }
  // }, [schedulerState.view])

  useEffect(() => {
    const { currentProject } = context;
    if (currentProject) {
      const { monthStart, monthEnd, queryStart, queryEnd } = handleDateRanges(schedulerState.date);
      setSchedulerState(prev => ({
        ...prev,
        startDate: monthStart,
        endDate: monthEnd
      }));
      listAppointmentsByProject(context.currentProject.id, queryStart, queryEnd, filterDuplicateAddresses);
      // listDuplicateLocationsForWorkOrders(context.currentProject.id, queryStart, queryEnd);
      fetchUsers();
    }
    return () => {
      setSchedulerData([]);
      setError(null);
    }
  }, []);

  useEffect(() => {
    // Event listener in its own useEffect here, because it relies on scheduler data to perform accurately
    const onEmptySlotSingleClick = (e) => {
      const { start, end } = e.detail;
      const appointments = schedulerData ? schedulerData.filter(item => item.appointmentStartDate >= start && item.appointmentEndDate <= end): [];
      setSelectedAppointments(appointments);
    }
    document.addEventListener("singleClickEmptySlot", onEmptySlotSingleClick);
    setData(schedulerData);
    return () => {
      document.removeEventListener("singleClickEmptySlot", onEmptySlotSingleClick);
    }
  }, [schedulerData, setSelectedAppointments]);

  useEffect(() => {
    const onAppointmentShiftClick = (e, callback) => {
      callback(prev => {
        const appointmentIndex = prev.findIndex(x => x === e.detail);
        const nextState = [...prev];
        appointmentIndex >= 0 ? nextState.splice(appointmentIndex, 1) : nextState.push(e.detail);
        return nextState;
      });
    }
    const onAppointmentSingleClick = (e, callback) => {
      callback([e.detail])
    }
    const onAppointmentDoubleClick = (e, callback) => {
      callback(true);
    }
    const onEmptySlotDoubleClick = (e, callback, setModal) => {
      callback([{
        appointmentStartDate: e.detail.start,
        appointmentEndDate: e.detail.end,
        workOrderNumber: '',
        userIds: '',
        appointmentSequence: '',
        appointmentNotes: '',
      }])
      setModal(true);
    }
    const onDeleteClick = (e, callback, setModal) => {
      callback([e.detail]);
      setModal(true);
    }
    document.addEventListener("appointmentShiftClick", e => onAppointmentShiftClick(e, setSelectedAppointments));
    document.addEventListener("appointmentSingleClick", e => onAppointmentSingleClick(e, setSelectedAppointments));
    document.addEventListener("appointmentDoubleClick", e => onAppointmentDoubleClick(e, setSchedulerFormModal));
    document.addEventListener("doubleClickEmptySlot", e => onEmptySlotDoubleClick(e, setSelectedAppointments, setSchedulerFormModal));
    document.addEventListener("deleteClick", e => onDeleteClick(e, setSelectedAppointments, setDeleteModal));
    return () => {
      document.removeEventListener("appointmentShiftClick", onAppointmentShiftClick);
      document.removeEventListener("appointmentSingleClick", onAppointmentSingleClick);
      document.removeEventListener("appointmentDoubleClick", onAppointmentDoubleClick);
      document.removeEventListener("doubleClickEmptySlot", onEmptySlotDoubleClick);
      document.removeEventListener("deleteClick", onDeleteClick);
    };
  }, []);

  const fetchUsers = async () => {
    const { currentProject } = context;
    const users = await listProjectUserByWorkOrderStatusAndCount(currentProject.id);
    setTechnicianUsers(users);
  }

  const handleAssignmentChange = (event, action) => {
    const { value: userID } = event;
    const { assigned, unassigned } = assignmentsList
    const assignedIndex = assigned.findIndex(x => x === userID);
    const unassignedIndex = unassigned.findIndex(x => x === userID);
    if(action === 'assign'){
      assignedIndex >= 0 ? assigned.splice(assignedIndex, 1) : assigned.push(userID);
      unassignedIndex >= 0 && unassigned.splice(unassignedIndex, 1);
      setAssignmentsList({
        assigned: assigned,
        unassigned: unassigned
      });
    } else if(action === 'unassign'){
      unassignedIndex >= 0 ? unassigned.splice(unassignedIndex, 1) : unassigned.push(userID);
      assignedIndex >= 0 && assigned.splice(assignedIndex, 1);
      setAssignmentsList({
        assigned: assigned,
        unassigned: unassigned
      });
    }
  }

  const handleConfirmAssignments = async() => {
    const { assigned, unassigned } = assignmentsList;
    const { currentProject } = context;
    const handleWorkOrderAssignments = async (mutation, users) => {
      try {
        await client.mutate({
          mutation,
          variables: {
            projectId: currentProject.id,
            userIds: users,
            workOrderIds: selectedAppointments.map(({ workOrderId }) => workOrderId)
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (assigned.length > 0) {
      await handleWorkOrderAssignments(ASSIGN_WORKORDERS_MUTATION, assigned);
    }
    if (unassigned.length > 0) {
      await handleWorkOrderAssignments(UNASSIGN_WORKORDERS_MUTATION, unassigned);
    }
    fetchUsers();
    listAppointmentsByProject(currentProject.id);
    setAssignmentModal(false);
    setAssignmentsList({
      assigned: [],
      unassigned: [],
    })
  }

  const extractTimes = (date) => {
    return Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: context.currentProject.projectTimeZone
    }).format(date).split(':').map(x => Number(x));
  }

  const checkHours = (appointment, type) => {
    // TODO :: find business hours
    const [startBusinessHour, startBusinessMinute] = "07:00".split(':').map(x => Number(x));
    const [endBusinessHour, endBusinessMinute] = "19:00".split(':').map(x => Number(x));
    const [startAppointmentHour, startAppointmentMinute] = extractTimes(appointment.appointmentStartDate);
    const [endAppointmentHour, endAppointmentMinute] = extractTimes(appointment.appointmentEndDate);
    const appointmentTooSoon = startAppointmentHour < startBusinessHour || (startAppointmentHour === startBusinessHour && startAppointmentMinute < startBusinessMinute)
    const appointmentTooLate = endAppointmentHour > endBusinessHour || (endAppointmentHour === endBusinessHour && endAppointmentMinute > endBusinessMinute)
    if(appointmentTooSoon || appointmentTooLate){
      setAppointmentForm({ appointment, type });
      setAppointmentHoursModal(true);
    } else {
      handleFormSubmission(appointment, type);
    }
  }

  const handleFormSubmission = (appointment, type) => {
    const formData = appointment;
    const { currentProject } = context;
    if (type === 'create') {
      if(appointment.override){
        setShowAppointmentAlreadyExistModal(true);
      } else {
        createAppointment();
      }
    }
    if (type === 'update') {
      const updateInput = {
        ...formData,
        timeZone: currentProject.projectTimeZone
      }
      delete updateInput['isAllDay'];
      delete updateInput['workOrderNumber'];
      if (updateInput.appointmentStartDate < new Date()) {
        setAppointmentStartDateWarning(true);
      }
      setNewAppointment(updateInput)
      updateAppointment();
    }
    if (type === 'delete') {
      const { id: appointmentId, projectId, workOrderId } = appointment;
      deleteAppointment(appointmentId, projectId, workOrderId);
      setDeleteModal(false);
    }
    setSchedulerFormModal(false);
  };

  const handleDataChange = ({ created, updated, deleted }) => {
    const { currentProject } = context;
    if (created.length > 0 && !created[0].override) {
      createAppointment();
    }
    if (updated.length > 0) {
      const formData = updated[0];
      const updateInput = {
        ...formData,
        timeZone: currentProject.projectTimeZone
      }
      delete updateInput['isAllDay'];
      delete updateInput['workOrderNumber'];
      if (updateInput.appointmentStartDate < new Date()) {
        setAppointmentStartDateWarning(true);
      }
      setNewAppointment(updateInput)
      updateAppointment();
    }
    if (deleted.length > 0) {
      const { id: appointmentId, projectId, workOrderId } = deleted[0];
      deleteAppointment(appointmentId, projectId, workOrderId);
    }
  };

  //TODO: pass scheduler date range into refresh view
  const refreshView = () => {
    const { currentProject } = context;
    if (currentProject) {
      const { queryStart, queryEnd } = handleDateRanges(schedulerState.date);
      listAppointmentsByProject(currentProject.id, queryStart, queryEnd, filterDuplicateAddresses);
    }
    return () => {
      setSchedulerData([]);
      setError(null);
    };
  }

  const dateManipulator = (date) => {
    // Writing this to try and make the logic more readable
    // .setDate/.setMonth returns a unix time stamp so this prevents nesting new Date()
    if(!date) date = new Date();
    const nd = x => new Date(x);
    const clone = nd(date);
    return {
      date: clone,
      setMonth: x => dateManipulator(nd(clone.setMonth(x))),
      addMonth: x => dateManipulator(nd(clone.setMonth(clone.getMonth() + x))),
      setDate: x => dateManipulator(nd(clone.setDate(x))),
      addDate: x => dateManipulator(nd(clone.setDate(clone.getDate() + x))),
      setHours: (x, y, z) => {
        if(!x && x !== 0) x = clone.getHours();
        if(!y && y !== 0) y = clone.getMinutes();
        if(!z && z !== 0) z = clone.getSeconds();
        return dateManipulator(nd(clone.setHours(x, y, z, 0)))
      },
    }
  }

  const handleDateRanges = (input) => {
    const date = new Date(input);
    const monthStart = dateManipulator(date).setDate(1).date;
    const monthEnd = dateManipulator(monthStart).addMonth(1).setDate(0).setHours(23, 59, 59).date;
    // Give 8 days on both ends to make sure the end of previous month, and start of next month show up on calendar.
    const queryStart = dateManipulator(monthStart).addDate(-8).setHours(0,0,0).date;
    const queryEnd = dateManipulator(monthEnd).addDate(8).setHours(23, 59, 59).date;
    return {
      monthStart,
      monthEnd,
      queryStart,
      queryEnd,
      date
    }
  }

  const handleDateChange = ({ value }) => {
    // Scheduler sends different styles of values based on how you interact with component
    const clone = value._localDate ? value._localDate : value;
    const { monthStart, monthEnd, queryStart, queryEnd, date } = handleDateRanges(clone);
    if(date >= schedulerState.startDate && date <= schedulerState.endDate){
      setSchedulerState(prev => ({
        ...prev,
        date
      }));
    } else {
      setSchedulerState(prev => ({
        ...prev,
        date,
        startDate: monthStart,
        endDate: monthEnd
      }));
      listAppointmentsByProject(context.currentProject.id, queryStart, queryEnd, filterDuplicateAddresses);
    }
  }

  const handleViewChange = ({ value }) => {
    setSchedulerState(prev => ({...prev, view: value}))
  }

  const handleFilterDuplicateAddresses = (evt) =>{
    const { queryStart, queryEnd } = handleDateRanges(schedulerState.date);
    listAppointmentsByProject(context.currentProject.id, queryStart, queryEnd, evt.value);
    setFilterDuplicateAddresses(evt.value);
  }

  const CustomTask = (props) => {
    const itemRowStyle = { display: 'flex', gap: '5px' }
    const appointment = props.dataItem;
    const technicians = appointment.userIds.map(x => x.fullName);
    useEffect(() => {
      const appointmentSlot = document.getElementById(props.dataItem.id);
      if(selectedAppointments.findIndex(({ id } ) => id === props.dataItem.id) >= 0){
        appointmentSlot.parentNode.parentNode.classList.add('custom-selected-slot');
      } else {
        appointmentSlot.parentNode.parentNode.classList.remove('custom-selected-slot');
      }
    }, [selectedAppointments]);
    useEffect(() => {
      const shiftClickEvent = new CustomEvent("appointmentShiftClick", { detail: props.dataItem });
      const clickEvent = new CustomEvent("appointmentSingleClick", { detail: props.dataItem });
      const dblClickEvent = new CustomEvent("appointmentDoubleClick", { detail: props.dataItem });
      const appointmentSlot = document.getElementById(props.dataItem.id).parentNode.parentNode;
      appointmentSlot.classList.add('custom-slot')
      if(selectedAppointments.findIndex(({ id } ) => id === props.dataItem.id) >= 0) appointmentSlot.classList.add('custom-selected-slot')
      const singleClick = (e) => {
        if(e.metaKey) document.dispatchEvent(shiftClickEvent);
        if(!e.metaKey) document.dispatchEvent(clickEvent);
      };
      const doubleClick = (e) => {
        document.dispatchEvent(dblClickEvent);
      };
      appointmentSlot.addEventListener('click', singleClick);
      appointmentSlot.addEventListener('dblclick', doubleClick);
      return () => {
        appointmentSlot.removeEventListener('click', singleClick);
        appointmentSlot.removeEventListener('dblclick', doubleClick);
      }
    }, []);
    return (
      <div id={props.dataItem.id} className={'customer-scheduler-slot'} style={{whiteSpace: 'normal', width: '100%', marginBottom: '15px', paddingLeft: '10px'}}>
        <div>
          <div><b>WORK ORDER #: {appointment.workOrderNumber}</b></div>
        </div>
        <div><b>CUSTOMER NAME:</b> {appointment.customerName}</div>
        <div><b>DISTRICT CODE:</b> {appointment.districtCode}</div>
        <div>
          {technicians.length > 0 && (
            <div style={itemRowStyle}>
              <div><b>ASSIGNMENTS:</b> {technicians.join(' | ')}</div>
            </div>
          )}
          <div style={itemRowStyle}>
            <div><b>NOTES:</b></div>
            <div>{appointment.appointmentNotes}</div>
          </div>
        </div>
      </div>
    )
  }

  const renderTopMenu = () => {
    const { currentProject, user } = context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Appointments` : 'Appointments'}`,
        iconName: 'flag'
      },
      tabs: [
        {
          iconName: '',
          title: currentProject ? currentProject.projectTimeZone : '',
        },
        {
          active: false,
          onClick: refreshView,
          iconName: 'refresh',
          title: 'Refresh',
        },
        ...(isEnabled(user && user.userRoles, [ROLES.SystemAdmin, ROLES.CSR]) ? [{
          active: false,
          onClick: () => setCapacityModal(true),
          title: 'Capacity',
        }] : [])
      ]
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} appointmentView />;
  }

  const renderSchedulerRouteContent = () => {
    const { currentProject, user } = context;
    const appointmentManagement = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.appointmentManagement ? currentProject.projectConfiguration.additionalFeatures.appointmentManagement : false;
    if (currentProject) {
      return (
        <>
          {!appointmentManagement && <Redirect to={routeMaps.dashboard} />}
          <KendoScheduler
            ref={schedulerRef}
            slot={rest => (
              <CustomSlot
                {...rest}
                
                view={schedulerState.view}
                timeZone={currentProject.projectTimeZone}
              />
            )}
            editItem={(props) => {
              const editable = isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin, ROLES.ClientAdmin]);
              return (
                <EditItemWithDynamicTitle
                  {...props}
                  editable={editable}
                  isSelected={selectedAppointments.findIndex(({ id }) => id === props.dataItem.id) >= 0}
                />
              );
            }}
            onViewChange={handleViewChange}
            view={schedulerState.view}
            height={'88%'}
            timezone={currentProject.projectTimeZone}
            data={data}
            editable={isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin, ROLES.ClientAdmin])}
            modelFields={CUSTOM_MODEL_FIELDS}
            onDataChange={handleDataChange}
            form={FormWithCustomEditor}
            onDateChange={handleDateChange}
            date={schedulerState.date}
          >
            <AgendaView workDayStart={"07:00"} workDayEnd={"19:00"} className={'custom-agenda-view'} viewTask={props => <CustomTask selectedAppointments={selectedAppointments} {...props} />} />
            <TimelineView workDayStart={"07:00"} workDayEnd={"19:00"} />
            <DayView workDayStart={"07:00"} workDayEnd={"19:00"} dateHeaderCell={(props) => <CustomDateHeaderForDayView {...props}/>} timeHeaderCell={(props) => <CustomTimeHeaderForWeekAndDayView {...props} />} />
            <WeekView workDayStart={"07:00"} workDayEnd={"19:00"} dateHeaderCell={(props) => <CustomDateHeaderForWeekView {...props}/>} timeHeaderCell={(props) => <CustomTimeHeaderForWeekAndDayView {...props} />} />
            <MonthView workDayStart={"07:00"} workDayEnd={"19:00"} dateHeaderCell={(props) => <CustomDateHeaderForMonthView {...props}/>} />
          </KendoScheduler>

          {showAppointmentAlreadyExistModal && <OverrideAppointmentDialog />}
          {schedulerFormModal && (
            <CustomSchedulerForm
              openSharedAddressModal={() => setSharedWorkOrderAddressModal(true)}
              filterDuplicateAddresses={filterDuplicateAddresses}
              onSubmit={handleFormSubmission}
              onClose={() => setSchedulerFormModal(false)}
              selectedWorkOrder={selectedAppointments[0]}
              setSelectedAppointments={setSelectedAppointments}
            />
          )}
          {deleteModal && (
            <ConfirmDeleteAppointmentDialog 
              onConfirm={() => handleFormSubmission(selectedAppointments[0], 'delete')}
              onCancel={() => setDeleteModal(false)}
            />
          )}
          {appointmentHoursModal && (
            <ConfirmAppointmentHoursDialog
              onConfirm={() => {
                handleFormSubmission(appointmentForm.appointment, appointmentForm.type);
                setAppointmentForm({ appointment: null, type: null});
                setAppointmentHoursModal(false);
              }}
              onCancel={() => setAppointmentHoursModal(false)}
            />
          )}
        </>
      )
    }
  }
  const renderAssignmentButton = () => {
    const { currentProject, user } = context;
    if (currentProject){
      const editable = isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin, ROLES.ClientAdmin]);
      if (editable) {
        return (
          <div className={'custom-scheduler-toolbar'}>
            <div>
                <Button
                  className='scheduler-assignments-button'
                  disabled={selectedAppointments.length <= 0}
                  onClick={() => setAssignmentModal(true)}
                >
                <Icon name='users' />
                Assignments
              </Button>
              <p>
                Hold Windows or &#8984; key to select multiple appointments.
              </p>
            </div>
            <div>
              <Switch onChange={handleFilterDuplicateAddresses} />
              <p>Filter Duplicate Address</p>
            </div>
            

          </div>
        )
      }
    }
  }

  return (<>
    <ProjectRouteWrapper>
      <Loader loading={loading} />
      {renderTopMenu()}
      {renderAssignmentButton()}
      {renderSchedulerRouteContent()}
      <NotificationGroup appointmentStartDateWarning={appointmentStartDateWarning} setAppointmentStartDateWarning={setAppointmentStartDateWarning} />
    </ProjectRouteWrapper>
    { sharedWorkOrderAddressModal && 
      <WorkOrdersWithSharedAddress
        selectedWorkOrder={selectedAppointments[0]}
        closeModal={() => setSharedWorkOrderAddressModal(false)}
      />
    }
    { capacityModal && (
      <CapacityModal
        onClose={() => setCapacityModal(false)}
      />
    )}
    {technicianUsers && assignmentModal &&
      <WorkOrdersAssignmentsModalScheduler
        selectedAppointments={selectedAppointments}
        users={technicianUsers}
        assignmentsList={assignmentsList}
        handleAssignmentChange={handleAssignmentChange}
        closeModal={() => setAssignmentModal(false)}
        onModalConfirm={handleConfirmAssignments}
      />
    }
  </>)
}




const mapStateToProps = ({ scheduler }) => {
  return {
    showAppointmentAlreadyExistModal: scheduler.showAppointmentAlreadyExistModal,
    schedulerData: scheduler.data,
    loading: scheduler.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowAppointmentAlreadyExistModal: (showModal) => dispatch(schedulerActions.setShowAppointmentAlreadyExistModal(showModal)),
    listAppointmentsByProject: (projectId, startDate, endDate, filterDuplicateAddresses) => dispatch(schedulerActions.listAppointmentsByProject(projectId, startDate, endDate, filterDuplicateAddresses)),
    listDuplicateLocationsForWorkOrders: (project, startDate, endDate) => dispatch(schedulerActions.listDuplicateLocationsForWorkOrders(project, startDate, endDate)),
    deleteAppointment: (appointmentId, projectId, workOrderId) => dispatch(schedulerActions.deleteAppointment(appointmentId, projectId, workOrderId)),
    createAppointment: () => dispatch(schedulerActions.createAppointment()),
    updateAppointment: () => dispatch(schedulerActions.updateAppointment()),
    setNewAppointment: (newAppointment) => dispatch(schedulerActions.setNewAppointment(newAppointment)),
    setSchedulerData: () => dispatch(schedulerActions.setData()),
    setError: (error) => dispatch(schedulerActions.setError(error)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentRoute);





// const CustomTask = (props) => {
//   const itemRowStyle = { display: 'flex', gap: '5px' }
//   const appointment = props.dataItem;
//   const selectedAppointments = props.selectedAppointments;
//   const technicians = appointment.userIds.map(x => x.fullName);
//   useEffect(() => {
//     const appointmentSlot = document.getElementById(props.dataItem.id);
//     if(selectedAppointments.findIndex(({ id } ) => id === props.dataItem.id) >= 0){
//       appointmentSlot.parentNode.parentNode.classList.add('custom-selected-slot');
//     } else {
//       appointmentSlot.parentNode.parentNode.classList.remove('custom-selected-slot');
//     }
//   }, [selectedAppointments]);
//   useEffect(() => {
//     const shiftClickEvent = new CustomEvent("appointmentShiftClick", { detail: props.dataItem });
//     const clickEvent = new CustomEvent("appointmentSingleClick", { detail: props.dataItem });
//     const dblClickEvent = new CustomEvent("appointmentDoubleClick", { detail: props.dataItem });
//     const appointmentSlot = document.getElementById(props.dataItem.id).parentNode.parentNode;
//     appointmentSlot.classList.add('custom-slot')
//     if(selectedAppointments.findIndex(({ id } ) => id === props.dataItem.id) >= 0) appointmentSlot.classList.add('custom-selected-slot')
//     const singleClick = (e) => {
//       if(e.metaKey) document.dispatchEvent(shiftClickEvent);
//       if(!e.metaKey) document.dispatchEvent(clickEvent);
//     };
//     const doubleClick = (e) => {
//       document.dispatchEvent(dblClickEvent);
//     };
//     appointmentSlot.addEventListener('click', singleClick);
//     appointmentSlot.addEventListener('dblclick', doubleClick);
//     return () => {
//       appointmentSlot.removeEventListener('click', singleClick);
//       appointmentSlot.removeEventListener('dblclick', doubleClick);
//     }
//   }, []);
//   return (
//     <div id={props.dataItem.id} className={'customer-scheduler-slot'} style={{whiteSpace: 'normal', width: '100%', marginBottom: '15px', paddingLeft: '10px'}}>
//       <div>
//         <div><b>WORK ORDER #: {appointment.workOrderNumber}</b></div>
//       </div>
//       {/* <div><b>CUSTOMER NAME:</b> {appointment.customerName}</div> */}
//       <div>
//         {technicians.length > 0 && (
//           <div style={itemRowStyle}>
//             <div><b>ASSIGNMENTS:</b> {technicians.join(' | ')}</div>
//           </div>
//         )}
//         <div style={itemRowStyle}>
//           <div><b>NOTES:</b></div>
//           <div>{appointment.appointmentNotes}</div>
//         </div>
//       </div>
//     </div>
//   )
// }