import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Table, Image, Popup } from 'semantic-ui-react';
import Loader from '../loader';
import { isEnabled } from '../../../core/services/auth.service';
import ROLES from '../../../constants/roles';
import StaticMap from '../static-map';
import CommentsTabContent from './commentsTabContent';
import HistoryTabContent from './historyTabContent';
import CustomDataTab from './customDataTab';
import AuditDataTab from './auditDataTab';
import Gallery from '../gallery';
import TabSectionDivider from './tabSectionDivider';
import CommentModal from '../modal/comment';
import * as workOrderService from '../../../core/services/workOrder.service';
import { getAppointmentByWorkOrder } from '../../../core/services/appointment.service';
import Splitter from './splitter';
import EDIT_ICON from '../../../assets/icons/baseline_edit_black_18dp.png';
import EditModal from '../../../routes/quality-assurance/components/modal/editModal';
import VerifyModal from '../../../routes/quality-assurance/components/modal/verifyModal';
import WarningIcon from './qualityAssuranceWarningIcon';
import { MODAL_TYPES_LABELS as TAB_TYPE } from '../../../constants/qaModalTypes';
import QualityAssuranceDetailsActions from './qualityAssuranceDetailsActions';
import DetailsActions from './detailsActions';
import Header from './header';
import QualityAssuranceHeader from './qualityAssuranceHeader';
import Menu from './menu';
import DetailsAppointmentModal from './detailsAppointmentModal';

const InfoTabContent = ({
  currentProject,
  setShowAppointmentModal,
  selectedWorkOrder,
  user,
  projectHasBlackOuts,
  rtuWorkOrder,
  updateWorkOrder,
  updateWorkOrderNewMeterNumber,
  handleEscalate,
  isQualityAssurance,
  getWorkOrderDetails,
  oldMeterReadingHighThreshold,
  qualityAssuranceType,
  allowOldMeterNumberEditing,
  setDetailsOpenCount,
  detailsOpenCount,
  appointmentManagement,
  qaDoubleEntryVerification,
  verifyWorkOrder,
  workOrdersVerified,
  projectMobileTemplates
}) => {
  const [show3StrikeCommentModal, setShow3StrikeCommentModal] = useState(false);
  const [showBlackoutCommentModal, setShowBlackoutCommentModal] = useState(false);
  const [showNeedsSiteTestCommentModal, setShowNeedsSiteTestCommentModal] = useState(false);
  const [showNeedsAppointmentCommentModal, setShowNeedsAppointmentCommentModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(true);
  const [showReasonCodesModal, setShowReasonCodesModal] = useState(false);

  const [showNewRegisterNumberEditModal, setShowNewRegisterNumberEditModal] = useState(false);
  const [showNewRadioNumberEditModal, setShowNewRadioNumberEditModal] = useState(false);
  const [showNewMeterReadingEditModal, setShowNewMeterReadingEditModal] = useState(false);
  const [showNewMeterNumberEditModal, setShowNewMeterNumberEditModal] = useState(false);
  const [showOldMeterReadingEditModal, setShowOldMeterReadingEditModal] = useState(false);
  const [showOldMeterNumberEditModal, setShowOldMeterNumberEditModal] = useState(false);
  const [showShowNewLidSizeEditModal, setShowNewLidSizeEditModal] = useState(false);

  const scrollRef = React.createRef();

  useEffect(() => {
    if (detailsOpenCount === 0) {
      isQualityAssurance && scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setDetailsOpenCount(1);
    }
  }, []);

  const on3StrikeChange = () => {
    setShow3StrikeCommentModal(true);
  }

  const onNeedsAppointmentChange = () => {
    if (selectedWorkOrder.workOrderNeedsAppointment) {
      updateNeedsAppointment();
    } else {
      setShowNeedsAppointmentCommentModal(true);
    }
  }

  const updateNeedsAppointment = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderNeedsAppointment: !selectedWorkOrder.workOrderNeedsAppointment });
  }

  const onCloseNeedsAppointmentCommentModal = () => {
    setShowNeedsAppointmentCommentModal(false);
    updateNeedsAppointment();
  }

  const onClose3StrikeCommentModal = async () => {
    setShow3StrikeCommentModal(false);
    await rtuWorkOrder({ id: selectedWorkOrder.id, workOrderFlaggedAs3strike: !selectedWorkOrder.workOrderFlaggedAs3strike, currentUserId: user.id });
  }

  const onBlackoutChange = () => {
    setShowBlackoutCommentModal(true);
  }

  const onCloseBlackoutCommentModal = async () => {
    setShowBlackoutCommentModal(false);
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderIsInBlackOut: !selectedWorkOrder.workOrderIsInBlackOut, workOrderReviewedUserId: user.id })
  }

  const onNeedsSiteTestChange = () => {
    if (selectedWorkOrder.workOrderNeedsSiteTest) {
      updateNeedsSiteTest();
    } else {
      setShowNeedsSiteTestCommentModal(true);
    }
  }

  const updateNeedsSiteTest = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderNeedsSiteTest: !selectedWorkOrder.workOrderNeedsSiteTest });
  }

  const onCloseNeedsSiteTestCommentModal = () => {
    setShowNeedsSiteTestCommentModal(false);
    updateNeedsSiteTest();
  }

  const onNewMeterNotDeployedChange = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, newMeterNotDeployed: !selectedWorkOrder.newMeterNotDeployed });
  }

  const onNewRadioNotDeployed = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, newRadioNotDeployed: !selectedWorkOrder.newRadioNotDeployed });
  }

  const onFlaggedAsCNCChange = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderFlaggedAsCNC: !selectedWorkOrder.workOrderFlaggedAsCNC });
  }

  const onNewRegisterNotDeployedChange = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, newRegisterNotDeployed: !selectedWorkOrder.newRegisterNotDeployed, workOrderReviewedUserId: user.id });
  }

  const onFlaggedAsDamagedChange = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderFlaggedAsDamaged: !selectedWorkOrder.workOrderFlaggedAsDamaged });
  }

  const updateNewRegisterNumber = async (id, newRegisterNumber) => {
    await updateWorkOrder({ id, newRegisterNumber, workOrderReviewedUserId: user.id });
  }

  const updateNewRadioNumber = async (id, newRadioNumber) => {
    await updateWorkOrder({ id, newRadioNumber, workOrderReviewedUserId: user.id });
  }

  const updateNewLidSize = async (id, newLidSize) => {
    const lidSizeValue = newLidSize === null ? "" : newLidSize;
    await updateWorkOrder({ id, newLidSize: lidSizeValue, workOrderReviewedUserId: user.id });
  }

  const updateNewMeterReading = async (id, newMeterReading) => {
    await updateWorkOrder({ id, newMeterReading, workOrderReviewedUserId: user.id });
  }

  const updateNewMeterNumber = async (id, newMeterNumber) => {
    await updateWorkOrderNewMeterNumber(id, newMeterNumber);
  }

  const updateOldMeterReading = async (id, oldMeterReading) => {
    await updateWorkOrder({ id, oldMeterReading, workOrderReviewedUserId: user.id });
  }

  const updateOldMeterNumber = async (id, oldMeterNumber) => {
    await updateWorkOrder({ id, oldMeterNumber, workOrderReviewedUserId: user.id });
  }

  const getReasonCodeOptions = () => {
    let options = [];

    // Helper function to recursively find switch groups
    const findSwitchGroups = (items) => {
      if (!items || !Array.isArray(items)) return;

      items.forEach(function(item) {
        // Check if this is a reason codes switch group
        if (item.type === 'switch-group' && 
            item.dataProperty === 'workOrderReasonCodes' && 
            item.items && 
            Array.isArray(item.items)) {
          options = options.concat(item.items.map(function(code) {
            return code.title;
          }));
        }
        
        // Recursively check any nested items
        if (item.items) {
          findSwitchGroups(item.items);
        }
        if (item.components) {
          findSwitchGroups(item.components);
        }
      });
    };

    // Process each screen's components
    if (currentProject && currentProject.projectMobileTemplates && currentProject.projectMobileTemplates.mobileTemplates) {
      currentProject.projectMobileTemplates.mobileTemplates.forEach(function(mobileTemplate) {
        if (!mobileTemplate.screens) return;

        mobileTemplate.screens.forEach(function(screen) {
          if (!screen.components || !Array.isArray(screen.components)) return;
          findSwitchGroups(screen.components);
        });
      });
    }

    // Remove duplicates and sort
    return options.filter(function(item, pos) {
      return options.indexOf(item) === pos;
    }).sort();
  };

  const getCurrentReasonCodes = () => {
    const options = getReasonCodeOptions();
    let reasonCodes = [];
    
    if (selectedWorkOrder && selectedWorkOrder.workOrderReasonCodes) {
      reasonCodes = selectedWorkOrder.workOrderReasonCodes;
    }
    
    return reasonCodes.filter(function(code) {
      return options.indexOf(code) !== -1;
    });
  };

  const shouldSetWarningSign = (primary, secondary) => {
    return oldMeterReadingHighThreshold && ((selectedWorkOrder[primary] < selectedWorkOrder[secondary]) || (selectedWorkOrder[primary] - selectedWorkOrder[secondary]) > oldMeterReadingHighThreshold);
  }

  const formatQualityAssuranceField = (primary, secondary, onClick, condition, label, warningIcon, disableEdit = false) => {
    if (isQualityAssurance) {
      if (selectedWorkOrder[condition]) {
        return <span style={{ fontWeight: 'bold' }}>{label}</span>
      }
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {selectedWorkOrder && selectedWorkOrder[primary] !== null && selectedWorkOrder[primary] !== undefined ? <span>{selectedWorkOrder[primary]}{warningIcon && shouldSetWarningSign(primary, secondary) ? <WarningIcon /> : null}</span> : ''}
          </div>
          {!disableEdit && <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Image src={EDIT_ICON} style={{ cursor: 'pointer' }} onClick={onClick} />
          </div>}
        </div>
      )
    }
    return <div>{selectedWorkOrder && selectedWorkOrder[primary] !== null && selectedWorkOrder[primary] !== undefined ? <span>{selectedWorkOrder[primary]}{warningIcon && shouldSetWarningSign(primary, secondary) ? <WarningIcon /> : null}</span> : ''}</div>
  }

  const formatOldMeterNumberField = () => {
    if (allowOldMeterNumberEditing) {
      return formatQualityAssuranceField('oldMeterNumber', '', () => setShowOldMeterNumberEditModal(true));
    }
    return <div>{selectedWorkOrder && selectedWorkOrder.oldMeterNumber !== null && selectedWorkOrder.oldMeterNumber !== undefined ? selectedWorkOrder.oldMeterNumber : ''}</div>
  }

  const getNewLidSizeOptions = () => {
    if (projectMobileTemplates) {
      const mobileTemplates = projectMobileTemplates.mobileTemplates;
      if (mobileTemplates && mobileTemplates.length > 0) {
        const screens = mobileTemplates[0].screens || [];
        const newLidSizeScreen = screens.find(i => i.name === 'newLidSize-screen');
        if (newLidSizeScreen) {
          const components = newLidSizeScreen.components;
          if (components && components.length > 0) {
            const items = components[0].items;
            if (items && items.length > 0) {
              const options = items[0].items;
              if (options && options.length > 0) {
                return options.map(({ title }) => title);
              }
            }
          }
        }
      }
    }
    return [];
  }

  const hasAppointment = async (currentProject, selectedWorkOrder) => {
    if (currentProject) {
      let currentAppointment = "";
      currentAppointment = await getAppointmentByWorkOrder(currentProject.id, selectedWorkOrder.id);
      if (currentAppointment) return true;
    }
    return false;
  }

  const renderWaterDeployDetails = () => {
    const newLidSizeOptions = getNewLidSizeOptions();
    return (
      <>
        {show3StrikeCommentModal && <CommentModal
          description={selectedWorkOrder.workOrderFlaggedAs3strike ?
            'Caution: You are adding this work order to scope. Please add a comment.' :
            'Caution: You are removing this work order from scope and returning it to the utility. Please add a comment.'}
          showModal={show3StrikeCommentModal}
          closeModal={onClose3StrikeCommentModal}
          onCancel={() => setShow3StrikeCommentModal(false)}
          workOrderId={selectedWorkOrder.id}
          userId={user.id} />}
        {showBlackoutCommentModal && <CommentModal
          showModal={showBlackoutCommentModal}
          submitWithoutComment={true}
          closeModal={onCloseBlackoutCommentModal}
          onCancel={() => setShowBlackoutCommentModal(false)}
          workOrderId={selectedWorkOrder.id}
          userId={user.id} />}
        {showNeedsSiteTestCommentModal && <CommentModal
          submitWithoutComment={true}
          showModal={showNeedsSiteTestCommentModal}
          closeModal={onCloseNeedsSiteTestCommentModal}
          onCancel={() => setShowNeedsSiteTestCommentModal(false)}
          workOrderId={selectedWorkOrder.id}
          userId={user.id} />}
        {showNeedsAppointmentCommentModal && <CommentModal
          closeModal={onCloseNeedsAppointmentCommentModal}
          onCancel={() => setShowNeedsAppointmentCommentModal(false)}
          showModal={showNeedsAppointmentCommentModal}
          workOrderId={selectedWorkOrder.id}
          userId={user.id} />}
        {showVerifyModal && qaDoubleEntryVerification && isQualityAssurance && selectedWorkOrder && selectedWorkOrder.id && workOrdersVerified && !workOrdersVerified.includes(selectedWorkOrder.id) && !selectedWorkOrder.workOrderFlaggedAsDamaged && !selectedWorkOrder.workOrderFlaggedAs3strike && <VerifyModal
          addComment
          escalate
          handleEscalate={handleEscalate}
          userId={user.id}
          selectedWorkOrder={selectedWorkOrder}
          photoIndex={0}
          title={'Data Verification'}
          closeModal={setShowVerifyModal}
          showModal={showVerifyModal}
          inputValue={selectedWorkOrder.oldMeterReading ? selectedWorkOrder.oldMeterReading : selectedWorkOrder.oldMeterKwhReading}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateOldMeterReading}
          verifyWorkOrder={verifyWorkOrder}
          modalType='text' />}
        {showReasonCodesModal && selectedWorkOrder && selectedWorkOrder.id && (
          <EditModal
            title="Reason Codes"
            closeModal={function() { setShowReasonCodesModal(false); }}
            showModal={showReasonCodesModal}
            inputValue={getCurrentReasonCodes()}
            workOrderId={selectedWorkOrder.id}
            getWorkOrderDetails={getWorkOrderDetails}
            update={function(id, value) { 
              return updateWorkOrder({ 
                id, 
                workOrderReasonCodes: value,
                workOrderReviewedUserId: user.id,
                customDataFields: selectedWorkOrder.customDataFields || []
              }); 
            }}
            options={getReasonCodeOptions()}
            modalType="dropdown"
            multiple={true}
            isCustomData={false}
          />
        )}
        {showShowNewLidSizeEditModal && <EditModal
          title={'New Lid Size'}
          closeModal={() => setShowNewLidSizeEditModal(false)}
          showModal={showShowNewLidSizeEditModal}
          inputValue={selectedWorkOrder.newLidSize}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateNewLidSize}
          modalType='dropdown'
          options={getNewLidSizeOptions()} />}
        {showNewRadioNumberEditModal && <EditModal
          title={'New Radio Number'}
          closeModal={() => setShowNewRadioNumberEditModal(false)}
          showModal={showNewRadioNumberEditModal}
          inputValue={selectedWorkOrder.newRadioNumber}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateNewRadioNumber}
          modalType='text' />}
        {showNewRegisterNumberEditModal && <EditModal
          title={'New Register Number'}
          closeModal={() => setShowNewRegisterNumberEditModal(false)}
          showModal={showNewRegisterNumberEditModal}
          inputValue={selectedWorkOrder.newRegisterNumber}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateNewRegisterNumber}
          modalType='text' />}
        {showNewMeterReadingEditModal && <EditModal
          title={'New Meter Reading'}
          closeModal={() => setShowNewMeterReadingEditModal(false)}
          showModal={showNewMeterReadingEditModal}
          inputValue={selectedWorkOrder.newMeterReading}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateNewMeterReading}
          modalType='number' />}
        {showNewMeterNumberEditModal && <EditModal
          title={'New Meter Number'}
          closeModal={() => setShowNewMeterNumberEditModal(false)}
          showModal={showNewMeterNumberEditModal}
          inputValue={selectedWorkOrder.newMeterNumber}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateNewMeterNumber}
          modalType='text' />}
        {showOldMeterReadingEditModal && <EditModal
          title={'Old Meter Out Read'}
          closeModal={() => setShowOldMeterReadingEditModal(false)}
          showModal={showOldMeterReadingEditModal}
          inputValue={selectedWorkOrder.oldMeterReading}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateOldMeterReading}
          modalType='number' />}
        {showOldMeterNumberEditModal && <EditModal
          title={'Old Meter Number'}
          closeModal={() => setShowOldMeterNumberEditModal(false)}
          showModal={showOldMeterNumberEditModal}
          inputValue={selectedWorkOrder.oldMeterNumber}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateOldMeterNumber}
          modalType='text' />}
        <TabSectionDivider icon='bar chart' title='Status' />
        <Table definition compact>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={6}>Work Order Status</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderStatus !== null && selectedWorkOrder.workOrderStatus !== undefined ? selectedWorkOrder.workOrderStatus : ''}{selectedWorkOrder && selectedWorkOrder.workOrderEscalatedCategory !== null && selectedWorkOrder.workOrderEscalatedCategory !== undefined ? ' - Category: ' + selectedWorkOrder.workOrderEscalatedCategory : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Last Visit Date</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderLastVisitDate !== null && selectedWorkOrder.workOrderLastVisitDate !== undefined ? selectedWorkOrder.workOrderLastVisitDate : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Technician</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.userName !== null && selectedWorkOrder.userName !== undefined ? selectedWorkOrder.userName : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Assignments</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderAssignments !== null && selectedWorkOrder.workOrderAssignments !== undefined ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.workOrderAssignments}</div> : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Record Locator</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.recordLocator !== null && selectedWorkOrder.recordLocator !== undefined ? selectedWorkOrder.recordLocator : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Appointment-based</Table.Cell>
              <Table.Cell>
                {appointmentManagement ? (
                  <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                      <Popup
                        content='Use the Manage Appointment link to update'
                        trigger={(
                          <Checkbox
                            checked={selectedWorkOrder && selectedWorkOrder.workOrderNeedsAppointment !== null ? selectedWorkOrder.workOrderNeedsAppointment : false}
                            disabled={!hasAppointment(currentProject, selectedWorkOrder)}
                            onChange={onNeedsAppointmentChange}
                          />
                        )} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                      <span onClick={() => setShowAppointmentModal(true)} style={{ cursor: 'pointer', paddingLeft: 10, color: '#2285D0', 'textDecorationLine': 'underline' }}>Manage Appointment</span>
                    </div>
                  </div>
                ) : (
                  <Checkbox
                    checked={selectedWorkOrder && selectedWorkOrder.workOrderNeedsAppointment !== null ? selectedWorkOrder.workOrderNeedsAppointment : false}
                    onChange={onNeedsAppointmentChange}
                  />
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Flagged As 3 RTU</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={(!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin]) || selectedWorkOrder.workOrderStatus === 'InReview' || selectedWorkOrder.workOrderStatus === 'Closed') || (isQualityAssurance && qualityAssuranceType !== TAB_TYPE.ESCALATED)}
                  onChange={on3StrikeChange}
                  checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAs3strike !== null ? selectedWorkOrder.workOrderFlaggedAs3strike : false} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Flagged As RTC/Review</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={(!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin])) || (isQualityAssurance && qualityAssuranceType === TAB_TYPE.COMPLETED)}
                  onChange={onFlaggedAsCNCChange}
                  checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAsCNC !== null ? selectedWorkOrder.workOrderFlaggedAsCNC : false} />
              </Table.Cell>
            </Table.Row>
            {projectHasBlackOuts && <Table.Row>
              <Table.Cell width={6}>Is In Blackout</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin, ROLES.ClientAdmin])}
                  onChange={onBlackoutChange}
                  checked={selectedWorkOrder && selectedWorkOrder.workOrderIsInBlackOut !== null ? selectedWorkOrder.workOrderIsInBlackOut : false} />
              </Table.Cell>
            </Table.Row>}
            <Table.Row>
              <Table.Cell width={6}>Flagged as Specialty</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={isQualityAssurance}
                  checked={selectedWorkOrder && selectedWorkOrder.workOrderNeedsSiteTest !== null ? selectedWorkOrder.workOrderNeedsSiteTest : false}
                  onChange={onNeedsSiteTestChange} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Reason Codes</Table.Cell>
              <Table.Cell>
                {isQualityAssurance ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>
                      {selectedWorkOrder && selectedWorkOrder.workOrderReasonCodes ? selectedWorkOrder.workOrderReasonCodes.join(', ') : ''}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                      <Image src={EDIT_ICON} style={{ cursor: 'pointer' }} onClick={() => setShowReasonCodesModal(true)} />
                    </div>
                  </div>
                ) : (
                  <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>
                    {selectedWorkOrder && selectedWorkOrder.workOrderReasonCodes ? selectedWorkOrder.workOrderReasonCodes.join(', ') : ''}
                  </div>
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Deleted Date</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderDeletedDate !== null && selectedWorkOrder.workOrderDeletedDate !== undefined ? selectedWorkOrder.workOrderDeletedDate : ''}</Table.Cell>
            </Table.Row>
            {isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin]) && <Table.Row>
              <Table.Cell width={6}>Is Geocoded</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={true}
                  checked={selectedWorkOrder && selectedWorkOrder.workOrderIsGeocoded !== null ? selectedWorkOrder.workOrderIsGeocoded : false} />
              </Table.Cell>
            </Table.Row>}
          </Table.Body>
        </Table>

        <div ref={scrollRef}>
          <TabSectionDivider icon='sign out' title='Existing Assets' />
          <Table definition compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={6}>Old Meter Number</Table.Cell>
                <Table.Cell>{formatOldMeterNumberField()}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Old Meter Size</Table.Cell>
                <Table.Cell>{selectedWorkOrder && selectedWorkOrder.meterSize !== null && selectedWorkOrder.meterSize !== undefined ? selectedWorkOrder.meterSize : ''}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Old Meter Last Read</Table.Cell>
                <Table.Cell>{selectedWorkOrder && selectedWorkOrder.oldMeterLastReading !== null && selectedWorkOrder.oldMeterLastReading !== undefined ? selectedWorkOrder.oldMeterLastReading : ''}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Old Meter Out Read</Table.Cell>
                <Table.Cell>
                  {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                    <span>{selectedWorkOrder && selectedWorkOrder.oldMeterReading !== null && selectedWorkOrder.oldMeterReading !== undefined ? selectedWorkOrder.oldMeterReading : ''}</span> :
                    formatQualityAssuranceField(
                      'oldMeterReading',
                      'oldMeterLastReading',
                      qaDoubleEntryVerification ? () => setShowOldMeterReadingEditModal(true) : () => setShowOldMeterReadingEditModal(true),
                      'workOrderFlaggedAsDamaged',
                      'DAMAGED',
                      true
                    )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Flagged As Damaged</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    onChange={onFlaggedAsDamagedChange}
                    disabled={!isEnabled(user.userRoles, [ROLES.Admin, ROLES.QualityAssurance, ROLES.ClientAdmin, ROLES.SystemAdmin, ROLES.AccountAdmin]) || !isQualityAssurance}
                    checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAsDamaged !== null ? selectedWorkOrder.workOrderFlaggedAsDamaged : false} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Old Radio Number</Table.Cell>
                <Table.Cell>{selectedWorkOrder && selectedWorkOrder.oldRadioNumber !== null && selectedWorkOrder.oldRadioNumber !== undefined ? selectedWorkOrder.oldRadioNumber : ''}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Old Register Number</Table.Cell>
                <Table.Cell>{selectedWorkOrder && selectedWorkOrder.oldRegisterNumber !== null && selectedWorkOrder.oldRegisterNumber !== undefined ? selectedWorkOrder.oldRegisterNumber : ''}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Line Type</Table.Cell>
                <Table.Cell>{selectedWorkOrder && selectedWorkOrder.lineType !== null && selectedWorkOrder.lineType !== undefined ? selectedWorkOrder.lineType : ''}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Line Size</Table.Cell>
                <Table.Cell>{selectedWorkOrder && selectedWorkOrder.lineSize !== null && selectedWorkOrder.lineSize !== undefined ? selectedWorkOrder.lineSize : ''}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={6}>Lid Size</Table.Cell>
                <Table.Cell>{selectedWorkOrder && selectedWorkOrder.lidSize !== null && selectedWorkOrder.lidSize !== undefined ? selectedWorkOrder.lidSize : ''}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <TabSectionDivider icon='sign in' title='New Assets' />
        <Table definition compact>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={6}>No Meter Install</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin, ROLES.Admin])}
                  onChange={onNewMeterNotDeployedChange}
                  checked={selectedWorkOrder && selectedWorkOrder.newMeterNotDeployed !== null ? selectedWorkOrder.newMeterNotDeployed : false} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>New Meter Number</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.newMeterNumber !== null && selectedWorkOrder.newMeterNumber !== undefined ? selectedWorkOrder.newMeterNumber : ''}</span> :
                  formatQualityAssuranceField(
                    'newMeterNumber',
                    '',
                    () => setShowNewMeterNumberEditModal(true),
                    'newMeterNotDeployed',
                    ''
                  )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Asset Number</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.newMeterAssetNumber !== null && selectedWorkOrder.newMeterAssetNumber !== undefined ? selectedWorkOrder.newMeterAssetNumber : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>New Meter In Read</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.newMeterReading !== null && selectedWorkOrder.newMeterReading !== undefined ? selectedWorkOrder.newMeterReading : ''}</span> :
                  formatQualityAssuranceField('newMeterReading', '', () => setShowNewMeterReadingEditModal(true))}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>No Radio Install</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin, ROLES.Admin])}
                  onChange={onNewRadioNotDeployed}
                  checked={selectedWorkOrder && selectedWorkOrder.newRadioNotDeployed !== null ? selectedWorkOrder.newRadioNotDeployed : false} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>New Radio Number</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.newRadioNumber !== null && selectedWorkOrder.newRadioNumber !== undefined ? selectedWorkOrder.newRadioNumber : ''}</span> :
                  formatQualityAssuranceField('newRadioNumber', '', () => setShowNewRadioNumberEditModal(true))}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>New Radio Validated</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={true}
                  checked={selectedWorkOrder && selectedWorkOrder.newRadioValidated !== null ? selectedWorkOrder.newRadioValidated : false} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>No Register Install</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin, ROLES.Admin])}
                  onChange={onNewRegisterNotDeployedChange}
                  checked={selectedWorkOrder && selectedWorkOrder.newRegisterNotDeployed !== null ? selectedWorkOrder.newRegisterNotDeployed : false} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>New Register Number</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.newRegisterNumber !== null && selectedWorkOrder.newRegisterNumber !== undefined ? selectedWorkOrder.newRegisterNumber : ''}</span> :
                  formatQualityAssuranceField('newRegisterNumber', '', () => setShowNewRegisterNumberEditModal(true))}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>New Lid Size</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.newLidSize !== null && selectedWorkOrder.newLidSize !== undefined ? selectedWorkOrder.newLidSize : ''}</span> :
                  formatQualityAssuranceField('newLidSize', '', () => setShowNewLidSizeEditModal(true), null, null, null, newLidSizeOptions.length === 0)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <TabSectionDivider icon='address card' title='Service' />
        <Table definition compact>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={6}>Service Description</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.serviceDescription !== null && selectedWorkOrder.serviceDescription !== undefined ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.serviceDescription}</div> : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Service Type</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.serviceType !== null && selectedWorkOrder.serviceType !== undefined ? selectedWorkOrder.serviceType : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Location Id</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.locationId !== null && selectedWorkOrder.locationId !== undefined ? selectedWorkOrder.locationId : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Account Number</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.accountNumber !== null && selectedWorkOrder.accountNumber !== undefined ? selectedWorkOrder.accountNumber : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Customer Name</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.customerName !== null && selectedWorkOrder.customerName !== undefined ? selectedWorkOrder.customerName : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Street</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.street !== null && selectedWorkOrder.street !== undefined ? selectedWorkOrder.street : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Home Phone Number</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.homePhoneNumber !== null && selectedWorkOrder.homePhoneNumber !== undefined ? selectedWorkOrder.homePhoneNumber : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Mobile Phone Number</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.mobilePhoneNumber !== null && selectedWorkOrder.mobilePhoneNumber !== undefined ? selectedWorkOrder.mobilePhoneNumber : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Business Phone Number</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.businessPhoneNumber !== null && selectedWorkOrder.businessPhoneNumber !== undefined ? selectedWorkOrder.businessPhoneNumber : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Read Notes</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.meterReadNotes !== null && selectedWorkOrder.meterReadNotes !== undefined ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.meterReadNotes}</div> : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Route Code</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.routeCode !== null && selectedWorkOrder.routeCode !== undefined ? selectedWorkOrder.routeCode : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Book Code</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.bookCode !== null && selectedWorkOrder.bookCode !== undefined ? selectedWorkOrder.bookCode : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Billing Cycle</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.billingCycleCode !== null && selectedWorkOrder.billingCycleCode !== undefined ? selectedWorkOrder.billingCycleCode : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>District Code</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.districtCode !== null && selectedWorkOrder.districtCode !== undefined ? selectedWorkOrder.districtCode : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Customer Contacted</Table.Cell>
              <Table.Cell>
                <Checkbox
                  disabled={true}
                  checked={selectedWorkOrder && selectedWorkOrder.customerContactedOnSite !== null ? selectedWorkOrder.customerContactedOnSite : false} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Sequence</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.ReadSequence !== null && selectedWorkOrder.ReadSequence !== undefined ? selectedWorkOrder.ReadSequence : ''}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    )
  }

  return renderWaterDeployDetails();
}

const WaterDeployDetailsModal = ({
  user,
  showModal,
  closeModal,
  selectedWorkOrder,
  modalDetailsLoading,
  photoIndex,
  onGallerySlide,
  onNext,
  currentProject,
  isQualityAssurance,
  getWorkOrderDetails,
  fileInputRef,
  replacePhoto,
  showAddPhotoModal,
  verifyWorkOrder,
  workOrdersVerified,
  handleEscalate,
  handleReject,
  handleApprove,
  workOrders,
  handleApproveAndNext,
  qualityAssuranceType,
  getWorkOrdersWithStatusInReview,
  getWorkOrdersWithStatusEscalated,
  getWorkOrdersWithStatusCompleted,
  qualityAssuranceHeaderLabelColor,
  setDetailsOpenCount,
  detailsOpenCount,
  onReplacePhotoClick,
  setModalDetailsLoading,
  hideNext,
  ...rest
}) => {
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [closeWorkOrderUponApproval, setCloseWorkOrderUponApproval] = useState(false);

  useEffect(() => {
    if (selectedWorkOrder && selectedWorkOrder.customDataFields) {
      let isRework = selectedWorkOrder.customDataFields.findIndex(({ key }) => key === 'reworkReason') > -1;
      setCloseWorkOrderUponApproval(isRework);
    } else {
      setCloseWorkOrderUponApproval(false);
    }
  }, [selectedWorkOrder]);

  const onCloseWorkOrderUponApprovalChange = (e, { checked }) => {
    setCloseWorkOrderUponApproval(checked);
  }

  const rtuWorkOrder = async (workOrder) => {
    setModalDetailsLoading(true);
    if (qualityAssuranceType === TAB_TYPE.ESCALATED) {
      await workOrderService.rtuWorkOrder({ ...workOrder, workOrderType: selectedWorkOrder.workOrderType });
      getWorkOrderDetails(workOrder.id);
      if (workOrder.workOrderFlaggedAs3strike) {
        getWorkOrdersWithStatusCompleted();
        closeModal();
      }
      getWorkOrdersWithStatusEscalated();
    } else if (qualityAssuranceType === TAB_TYPE.COMPLETED) {
      await workOrderService.rtuWorkOrder({ ...workOrder, workOrderType: selectedWorkOrder.workOrderType });
      await getWorkOrderDetails(workOrder.id);
      getWorkOrdersWithStatusCompleted();
      if (selectedWorkOrder.workOrderFlaggedAsEscalated) {
        getWorkOrdersWithStatusEscalated();
      }
      closeModal();
    } else {
      await rest.updateWorkOrder(selectedWorkOrder.id, () => workOrderService.rtuWorkOrder(workOrder));
    }
  }

  const updateWorkOrder = async (workOrder) => {
    setModalDetailsLoading(true);
    if (isQualityAssurance) {
      await workOrderService.updateWorkOrder({ ...workOrder, workOrderType: selectedWorkOrder.workOrderType });
      getWorkOrderDetails(workOrder.id);
      if (qualityAssuranceType === TAB_TYPE.IN_REVIEW) {
        getWorkOrdersWithStatusInReview();
      } else if (qualityAssuranceType === TAB_TYPE.ESCALATED) {
        getWorkOrdersWithStatusEscalated();
      }
    } else {
      rest.updateWorkOrder(selectedWorkOrder.id, () => workOrderService.updateWorkOrder({ ...workOrder, workOrderType: selectedWorkOrder.workOrderType }));
    }
  }

  const updateWorkOrderNewMeterNumber = async (id, newMeterNumber) => {
    if (isQualityAssurance) {
      await workOrderService.updateWorkOrderNewMeterNumber({
        projectId: selectedWorkOrder.project.id,
        workOrderType: selectedWorkOrder.workOrderType,
        workOrderId: id,
        newMeterNumber,
      }).catch(e => {
        throw e;
      });
      getWorkOrderDetails(selectedWorkOrder.id);
      if (qualityAssuranceType === TAB_TYPE.IN_REVIEW) {
        getWorkOrdersWithStatusInReview();
      } else if (qualityAssuranceType === TAB_TYPE.ESCALATED) {
        getWorkOrdersWithStatusEscalated();
      } else if (qualityAssuranceType === TAB_TYPE.COMPLETED) {
        getWorkOrdersWithStatusCompleted();
      }
    }
  }

  return (
    <>
      {showAppointmentModal && <DetailsAppointmentModal selectedWorkOrder={selectedWorkOrder} updateWorkOrder={rest.updateWorkOrder} onClose={() => setShowAppointmentModal(false)} showModal={showAppointmentModal} />}
      <Modal dimmer={{ style: { zIndex: 1001 } }} size='fullscreen' closeIcon open={showModal} onClose={closeModal}>
        <Loader loading={modalDetailsLoading} />
        <Modal.Header>
          {isQualityAssurance ?
            <QualityAssuranceHeader
              type={qualityAssuranceType}
              workOrders={workOrders}
              labelColor={qualityAssuranceHeaderLabelColor}
              currentProject={currentProject}
              selectedWorkOrder={selectedWorkOrder} /> :
            <Header currentProject={currentProject} selectedWorkOrder={selectedWorkOrder} />}
        </Modal.Header>
        <Modal.Content image scrolling className='modal-size'>
          <Splitter>
            <Modal.Description className='details-description'>
              <div>
                {selectedWorkOrder && <div>
                  <StaticMap workOrder={selectedWorkOrder} updateWorkOrder={updateWorkOrder} />
                </div>}
                <Menu
                  info={(props) => (
                    <InfoTabContent
                      currentProject={currentProject}
                      handleEscalate={handleEscalate}
                      qaDoubleEntryVerification={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification ? currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification : false}
                      projectMobileTemplates={currentProject && currentProject.projectMobileTemplates}
                      appointmentManagement={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.appointmentManagement ? currentProject.projectConfiguration.additionalFeatures.appointmentManagement : false}
                      setShowAppointmentModal={setShowAppointmentModal}
                      setDetailsOpenCount={setDetailsOpenCount}
                      detailsOpenCount={detailsOpenCount}
                      allowOldMeterNumberEditing={currentProject && currentProject.projectConfiguration ? currentProject.projectConfiguration.allowOldMeterNumberEditing : false}
                      qualityAssuranceType={qualityAssuranceType}
                      getWorkOrderDetails={getWorkOrderDetails}
                      oldMeterReadingHighThreshold={currentProject && currentProject.projectConfiguration ? currentProject.projectConfiguration.oldMeterReadingHighThreshold : undefined}
                      getWorkOrdersWithStatusInReview={getWorkOrdersWithStatusInReview}
                      isQualityAssurance={isQualityAssurance}
                      updateWorkOrderNewMeterNumber={updateWorkOrderNewMeterNumber}
                      workOrdersVerified={workOrdersVerified}
                      verifyWorkOrder={verifyWorkOrder}
                      selectedWorkOrder={selectedWorkOrder}
                      user={user}
                      projectHasBlackOuts={currentProject && currentProject.projectHasBlackOuts ? currentProject.projectHasBlackOuts : false}
                      updateWorkOrder={updateWorkOrder}
                      rtuWorkOrder={rtuWorkOrder}
                      {...props}
                    />
                  )}
                  additional={(props) => (
                    <CustomDataTab
                      verifyWorkOrder={verifyWorkOrder}
                      qaDoubleEntryVerification={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification ? currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification : false}
                      isQualityAssurance={isQualityAssurance}
                      workOrdersVerified={workOrdersVerified}
                      getWorkOrderDetails={getWorkOrderDetails}
                      updateWorkOrder={updateWorkOrder}
                      selectedWorkOrder={selectedWorkOrder}
                      currentProject={currentProject}
                      data={selectedWorkOrder.customDataFields}
                      user={user} {...props}
                    />
                  )}
                  audit={(props) => <AuditDataTab workOrderAuditDate={selectedWorkOrder.workOrderAuditDate} data={selectedWorkOrder.auditDataFields} {...props} />}
                  comments={(props) => (
                    <CommentsTabContent
                      workOrder={selectedWorkOrder}
                      user={user}
                      updateWorkOrder={updateWorkOrder}
                      verifyWorkOrder={verifyWorkOrder}
                      qaDoubleEntryVerification={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification ? currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification : false}
                      isQualityAssurance={isQualityAssurance}
                      workOrdersVerified={workOrdersVerified}
                      getWorkOrderDetails={getWorkOrderDetails}
                      selectedWorkOrder={selectedWorkOrder}
                      currentProject={currentProject}
                      data={selectedWorkOrder.customDataFields}
                      {...props}
                    />
                  )}
                  history={(props) => (
                    <HistoryTabContent
                      workOrder={selectedWorkOrder}
                      user={user}
                      updateWorkOrder={updateWorkOrder}
                      verifyWorkOrder={verifyWorkOrder}
                      qaDoubleEntryVerification={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification ? currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification : false}
                      isQualityAssurance={isQualityAssurance}
                      workOrdersVerified={workOrdersVerified}
                      getWorkOrderDetails={getWorkOrderDetails}
                      selectedWorkOrder={selectedWorkOrder}
                      currentProject={currentProject}
                      data={selectedWorkOrder.customDataFields}
                      {...props}
                    />
                  )}
                />
              </div>
            </Modal.Description>
            <Gallery onSlide={onGallerySlide} photoIndex={photoIndex} workOrder={selectedWorkOrder} />
          </Splitter>
        </Modal.Content>
        <Modal.Actions>
          {isQualityAssurance ? <QualityAssuranceDetailsActions
            closeWorkOrderUponApproval={closeWorkOrderUponApproval}
            onCloseWorkOrderUponApprovalChange={onCloseWorkOrderUponApprovalChange}
            onReplacePhotoClick={onReplacePhotoClick}
            type={qualityAssuranceType}
            user={user}
            fileInputRef={fileInputRef}
            replacePhoto={replacePhoto}
            showAddPhotoModal={showAddPhotoModal}
            selectedWorkOrder={selectedWorkOrder}
            handleEscalate={handleEscalate}
            handleReject={handleReject}
            handleApprove={handleApprove}
            disableApprove={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification && workOrdersVerified && !workOrdersVerified.includes(selectedWorkOrder.id) && selectedWorkOrder && !selectedWorkOrder.workOrderFlaggedAsDamaged && !selectedWorkOrder.workOrderFlaggedAs3strike}
            modalDetailsLoading={modalDetailsLoading}
            workOrders={workOrders}
            handleApproveAndNext={handleApproveAndNext}
            onNext={onNext}
          /> : <DetailsActions onNext={onNext} hideNext={hideNext} closeModal={closeModal} />}
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default WaterDeployDetailsModal;