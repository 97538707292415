import React from 'react';
import { connect } from 'react-redux';
import AppContext from '../../../../core/context/app.context';
import { AssignmentsEditor } from '../editors';
import ErrorMessage from '../../../../shared/components/messages/error';
import { Button } from "@progress/kendo-react-buttons";
import { FormElement } from '@progress/kendo-react-form';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Label, Error } from '@progress/kendo-react-labels';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { Loader } from '@progress/kendo-react-indicators';
import { Icon, Message } from 'semantic-ui-react';
import './index.css';

const startDateStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}

const CustomFormEditor = (props) => {
  const context = React.useContext(AppContext);
  const { formState, setFormState } = props;
  const startDateWarning = new Date(formState.appointmentStartDate) < new Date();

  const handleFormChange = (e, name) => {
    const value = e.value;
    if(name === 'appointmentStartDate'){
      setFormState(prev => ({
        ...prev,
        appointmentStartDate: value,
        appointmentEndDate: new Date(value.getTime() + 30 * 60000)
      }))
    } else {
      setFormState(prev => ({
        ...prev,
        [name]: value
      }))
    }
  }

  const openWODetails = () => {
      const url = `${window.location.origin}/dashboard/${context.currentProject.id}/${props.selectedWorkOrder.workOrderId}`;
      window.open(url, '_blank').focus();
  }

  // const getAppointmentEndDate = () => {
  //   if (props.valueGetter('appointmentStartDate')) {
  //     return new Date(props.valueGetter('appointmentStartDate').getTime() + 30 * 60000);
  //   } else {
  //     return null;
  //   }
  // }

  
  return (
    <FormElement horizontal={true}>

      { props.filterDuplicateAddresses &&
        <div className='duplicate-address-warning' onClick={props.openSharedAddressModal}>
          <Message warning><Icon name='warning' />Attention! This appointment address may have another work orders associated with it</Message>
        </div>
      }
      <div className='k-form-field'>
        <Label>
          Work Order Number
        </Label>
        {props.appointmentId || props.selectedWorkOrder.workOrderNumber.length > 0 ? (
          <Label style={{ flexDirection: 'initial', fontWeight: 'bold' }}>
            <div onClick={openWODetails} style={{ cursor: 'pointer', display: 'flex', alignItems: 'baseline', color: '#2285d0' }}>
              <span>{props.selectedWorkOrder.workOrderNumber}</span>
              <Icon name='external alternate' style={{ marginLeft: '4px', fontSize: '0.9em', transform: 'translateY(-1px)' }} />
            </div>
          </Label>
        ) : (
          <div className='k-form-field-wrap'>
            <Input onChange={e => handleFormChange(e, 'workOrderNumber')} value={formState.workOrderNumber} name={'workOrderNumber'} />
            {props.errors.workOrderNumber && <Error>{props.errors.workOrderNumber}</Error>}
            {props.workOrderNotFoundError && <Error>Work Order not found</Error>}
          </div>
        )}
      </div>

      <div className='k-form-field'>
        <Label>
          Dates
        </Label>
        <div className='k-form-field-wrap'>
          <div style={startDateStyle}>
            <DateTimePicker onChange={e => handleFormChange(e, 'appointmentStartDate')} value={formState.appointmentStartDate} name={'appointmentStartDate'} />
            <div style={{width: '10%'}}></div>
            {/* &nbsp;
            <Label>
              End
            </Label>
            &nbsp; */}
            <DateTimePicker onChange={e => handleFormChange(e, 'appointmentEndDate')} value={formState.appointmentEndDate} name={'appointmentEndDate'} />
          </div>
        </div>
      </div>

      {startDateWarning && <Message warning><Icon name='warning' /> Warning! Appointment will be created in the past.</Message>}

      <div className='k-form-field'>
        <Label>
          Assignments
        </Label>
        <div className='k-form-field-wrap'>
          <AssignmentsEditor onChange={e => handleFormChange(e, 'userIds')} value={formState.userIds} name={'userIds'} selectedWorkOrder={props.selectedWorkOrder} />
          {props.errors.userIds && <Error>{props.errors.userIds}</Error>}
        </div>
      </div>

      {/* 
      TODO: Make Sequence Number configurable by project
      */}

      {props.selectedWorkOrder.appointmentSequence !== null && (
        <div className='k-form-field'>
          <Label>
            Sequence Number
          </Label>
          <div className='k-form-field-wrap'>
            <Input onChange={e => handleFormChange(e, 'appointmentSequence')} value={formState.appointmentSequence} name={'appointmentSequence'} disabled={true} />
            {props.errors.appointmentSequence && <Error>{props.errors.appointmentSequence}</Error>}
          </div>
        </div>
      )}

      <div className='k-form-field'>
        <Label>
          Note
        </Label>
        <div className='k-form-field-wrap' style={{ marginBottom: 10 }}>
          <TextArea onChange={e => handleFormChange(e, 'appointmentNotes')} value={formState.appointmentNotes} name={'appointmentNotes'} rows={6} />
          {props.errors.appointmentNotes && <Error>{props.errors.appointmentNotes}</Error>}
        </div>
      </div>

      {props.validating && <><Loader size='small' type={'infinite-spinner'} /><span style={{ marginLeft: 10 }}>Checking time slot...</span></>}
      {props.validationMessage && <ErrorMessage message={props.validationMessage} style={{ margin: '10px 0px 0px 0px' }} headerStyle={{}} />}

    </FormElement>
  )
}





const mapStateToProps = ({ scheduler }) => {
  return {
    workOrderNotFoundError: scheduler.workOrderNotFoundError,
    validating: scheduler.validating,
    validationMessage: scheduler.validationMessage
  }
}

export default connect(mapStateToProps, null)(CustomFormEditor);