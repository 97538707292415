import React, { Component } from 'react';
import { Menu, Icon, Image, Dropdown } from 'semantic-ui-react';
import routeMaps from '../../../core/route-maps';
import AppContext from '../../../core/context/app.context';
import LOGO from '../../../assets/pw-web-logo.png';
import { logout, isDisabled, isEnabled } from '../../../core/services/auth.service';
import config from '../../../config';
import ROLES from '../../../constants/roles';
import { getProjectByProjectId } from '../../../core/services/project.service';
import * as workOrderService from '../../../core/services/workOrder.service';
import { listProjectUserByWorkOrderStatusAndCount } from '../../../core/services/user.service';
import TermsOfUseModal from '../modal/termsOfUse';
import PrivacyPolicyModal from '../modal/privacyPolicy';

const projectDropdownTrigger = (projectName) => (
  <span>
    <b>{projectName ? projectName : 'Select Project...'}</b>
  </span>
);

const tenantDropdownTrigger = (tenantName) => (
  <span>
    <b>{tenantName ? tenantName : 'Select Tenant...'}</b>
  </span>
);

const Version = ({ currentProject }) => (
  <div style={currentProject ? { color: 'dimGrey', paddingBottom: '20px', paddingLeft: '20px' } : { color: 'dimGrey', position: 'absolute', bottom: '20px', left: '20px' }}>Web Portal {config.version}</div>
)

const Logo = () => (
  <Menu.Item>
    <Menu.Header className='menu-logo'><Image src={LOGO} /></Menu.Header>
  </Menu.Item>
)

const CSRPortal = ({ user, history }) => (
  <AppContext.Consumer>{({ currentProject }) => (
    <React.Fragment>
      {user && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.CSR]) && (currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.csrPortal) ? (
        <Menu.Item>
          <Menu.Header className='menu-header'>
            <Icon inverted name='fax' /> Customer Service
          </Menu.Header>
          <Menu.Menu>
            <Menu.Item
              className='menu-item'
              active={false}
              onClick={() => history.push(routeMaps.csrPortal)}
            >{'Quick View'}</Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      ) : null}
    </React.Fragment>
  )}</AppContext.Consumer>
)

const Account = ({ user, history }) => (
  <React.Fragment>
    {user && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]) ? (
      <Menu.Item>
        <Menu.Header className='menu-header'>
          <Icon inverted name='building' /> Account
        </Menu.Header>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            active={false}
            onClick={() => history.push(routeMaps.administrationProjects)}
          >{'< Administration'}</Menu.Item>
        </Menu.Menu>
      </Menu.Item>
    ) : null}
  </React.Fragment>
)

const Project = ({ getProjectsDropdownOptions, onProjectChange, history, setOpenProjectDropdown }) => (
  <Menu.Item>
    <AppContext.Consumer>{({ user, currentProject }) => (
      <React.Fragment>
        <Menu.Header className='menu-header'><Icon inverted name='flag' /> Project</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name=''
            active={false}
            onClick={() => { }}
          >
            {user && user.userProjects && user.userProjects.items ? (
              <Dropdown
                trigger={projectDropdownTrigger(currentProject && currentProject.projectName ? currentProject.projectName : '')}
                scrolling
                options={getProjectsDropdownOptions(user.userProjects.items)}
                onChange={onProjectChange}
                selectOnBlur={false}
                onOpen={() => setOpenProjectDropdown(true)}
                onClose={() => setOpenProjectDropdown(false)} />
            ) : ''}
          </Menu.Item>
          <Menu.Item
            className='menu-item'
            name='dashboard'
            active={false}
            onClick={() => history.push(routeMaps.dashboard)}
          />
          {user && user.userRoles && (currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.projectPlanning) && <Menu.Item
            className='menu-item'
            name='planning'
            active={false}
            onClick={() => history.push(routeMaps.planning)}
          />}
          {currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.snapshots && currentProject.isDemo &&
            <Menu.Menu>
              <Menu.Item
                className='menu-item'
                name='snapshots'
                active={false}
                onClick={() => history.push(routeMaps.snapshots)}
              />
            </Menu.Menu>}
        </Menu.Menu>
      </React.Fragment>
    )}</AppContext.Consumer>
  </Menu.Item>
)

const WorkOrders = ({ history, currentProject, setWorkOrdersPageListViewActive, setWorkOrdersPageMapViewActive }) => (
  <React.Fragment>
    {currentProject ? (
      <Menu.Item>
        <Menu.Header className='menu-header'><Icon inverted name='pin' /> Work Orders</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='map view'
            active={false}
            onClick={() => {
              setWorkOrdersPageListViewActive(false);
              setWorkOrdersPageMapViewActive(true);
              history.push(routeMaps.workOrders);
            }}
          />
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='list view'
            active={false}
            onClick={() => {
              setWorkOrdersPageListViewActive(true);
              setWorkOrdersPageMapViewActive(false);
              history.push(routeMaps.workOrders);
            }}
          />
        </Menu.Menu>
        <AppContext.Consumer>{({ user }) => (
          isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin, ROLES.ClientAdmin]) && 
          currentProject && currentProject.projectConfiguration && 
          currentProject.projectConfiguration.additionalFeatures && 
          currentProject.projectConfiguration.additionalFeatures.appointmentManagement &&
          <Menu.Menu>
            <Menu.Item
              className='menu-item'
              name='appointments'
              active={false}
              onClick={() => history.push(routeMaps.appointment)}
            />
          </Menu.Menu>
        )}</AppContext.Consumer>
      </Menu.Item>
    ) : null}
  </React.Fragment>
)

const Inventory = ({ currentProject, user, history }) => {
  const nonSerializedInventory = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory ? currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory : false;
  return (
    <React.Fragment>
      {currentProject ? (
        <Menu.Item>
          <Menu.Header className='menu-header'><Icon inverted name='shipping' /> Inventory</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              className='menu-item'
              name={nonSerializedInventory ? 'serialized assets' : 'all assets'}
              active={false}
              onClick={() => history.push(nonSerializedInventory ? routeMaps.serializedAssets : routeMaps.inventory)}
            />
            {nonSerializedInventory && <Menu.Item
              className='menu-item'
              name='non-serialized assets'
              active={false}
              onClick={() => history.push(routeMaps.nonSerializedAssets)}
            />}
            {isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin, ROLES.ClientAdmin]) && (<Menu.Item
              className='menu-item'
              name='import assets'
              active={false}
              onClick={() => history.push(routeMaps.importAssets)}
            />)}
          </Menu.Menu>
        </Menu.Item>) : null}
    </React.Fragment>
  )
}

const QA = ({ currentProject, history, user }) => (
  <React.Fragment>
    {currentProject ? (
      <Menu.Item>
        <Menu.Header className='menu-header'><Icon inverted name='tachometer alternate' /> Quality Assurance</Menu.Header>
        <Menu.Menu>
          {isDisabled(user.userRoles, [ROLES.Viewer]) ? (
            <p className='menu-item' style={{ padding: '.5em 1.33333333em', color: 'rgba(255,255,255,.5)' }}>Review Approve</p>
          ) : (
            <Menu.Item
              className='menu-item'
              name='review approve'
              active={false}
              onClick={() => history.push(routeMaps.qualityAssurance)}
            />
          )}
        </Menu.Menu>
      </Menu.Item>) : null}
  </React.Fragment>
)

const ProjectTools = ({ currentProject, history }) => (
  <React.Fragment>
    {currentProject ? (
      <Menu.Item>
        <Menu.Header className='menu-header'><Icon inverted name='wrench' /> Project Tools</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='service type'
            active={false}
            onClick={() => history.push(routeMaps.projectToolsServiceType)}
          />
        </Menu.Menu>
      </Menu.Item>) : null}
  </React.Fragment>
)

const Analysis = ({ currentProject, history, user }) => (
  <React.Fragment>
    {currentProject && currentProject.projectConfiguration && (currentProject.projectConfiguration.hasOwnProperty('analysisChartId') || currentProject.projectConfiguration.hasOwnProperty('analysisMapIds')) ? (
      <Menu.Item>
        <Menu.Header className='menu-header'><Icon inverted name='chart bar' /> Analysis</Menu.Header>
        <Menu.Menu>
          {currentProject.projectConfiguration.hasOwnProperty('analysisMapIds') ? (
            <Menu.Item
              className='menu-item'
              name='map view'
              active={false}
              onClick={() => history.push(routeMaps.analysisMapView)}
            />
          ) : null}
          {currentProject.projectConfiguration.hasOwnProperty('analysisChartId') ? (
            <Menu.Item
              className='menu-item'
              name='charts view'
              active={false}
              onClick={() => history.push(routeMaps.analysisChartsView)}
            />
          ) : null}
        </Menu.Menu>
      </Menu.Item>) : null}
  </React.Fragment>
)

const Reports = ({ currentProject, history }) => (
  <React.Fragment>
    {currentProject ? (
      <Menu.Item>
        <Menu.Header className='menu-header'><Icon inverted name='file text' /> Reports</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='work orders report'
            active={false}
            onClick={() => history.push(routeMaps.workOrdersReport)}
          />
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='inventory report'
            active={false}
            onClick={() => history.push(routeMaps.inventoryReport)}
          />
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='technician report'
            active={false}
            onClick={() => history.push(routeMaps.technicianReport)}
          />
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='conditions report'
            active={false}
            onClick={() => history.push(routeMaps.conditionsReport)}
          />
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='exceptions report'
            active={false}
            onClick={() => history.push(routeMaps.exceptionsReport)}
          />
        </Menu.Menu>
        {currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.appointmentManagement &&
          <Menu.Menu>
            <Menu.Item
              className='menu-item'
              name='appointments report'
              active={false}
              onClick={() => history.push(routeMaps.appointmentsReport)}
            />
          </Menu.Menu>
        }
      </Menu.Item>) : null}
  </React.Fragment>
)

const AdministrationPeople = ({ user, history, currentTenant }) => (
  <React.Fragment>
    {(currentTenant && user && user.userRoles && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin])) || (user && user.userRoles && isEnabled(user.userRoles, [ROLES.SystemAdmin])) ? (
      <Menu.Item>
        <Menu.Header className='menu-header'><Icon inverted name='user outline' /> People</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='user management'
            active={false}
            onClick={() => history.push(routeMaps.userManagement)}
          />
          {isEnabled(user.userRoles, [ROLES.SystemAdmin]) &&
            <Menu.Item
              className='menu-item'
              name='qualifications'
              active={false}
              onClick={() => history.push(routeMaps.qualificationManagement)}
            />}
        </Menu.Menu>
      </Menu.Item>
    ) : null}
  </React.Fragment>
)

const General = ({ logout, setShowTermsOfUseModal, setShowPrivacyPolicyModal, history, user }) => (
  <Menu.Item>
    <Menu.Header className='menu-header'>
      <Icon inverted name='settings' /> General
    </Menu.Header>
    <Menu.Menu>
      <Menu.Item
        className='menu-item'
        name='help and support'
        active={false}
        onClick={() => window.open(config.supportUrl, '_blank')}
      />
      {( user && user.userRoles && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin])) ? (
      <Menu.Item
        className='menu-item'
        name='whats new'
        active={false}
        onClick={() => history.push(routeMaps.whatsNew)}
      />):null}
      <Menu.Item
        className='menu-item-small'
        name='terms of service'
        active={false}
        onClick={() => setShowTermsOfUseModal(true)}
      />
      <Menu.Item
        className='menu-item-small'
        name='privacy policy'
        active={false}
        onClick={() => setShowPrivacyPolicyModal(true)}
      />
      <Menu.Item
        className='menu-item-small'
        name='log out'
        active={false}
        onClick={logout}
      />
    </Menu.Menu>
  </Menu.Item>
)

const AdministrationProjects = ({
  history,
  setAdministrationActiveProjectsPageActive,
  setAdministrationInactiveProjectsPageActive,
  currentTenant
}) => (
  <Menu.Item>
    <Menu.Header className='menu-header'>
      <Icon inverted name='flag' /> Projects
    </Menu.Header>
    <Menu.Menu>
      {currentTenant && <Menu.Item
        className='menu-item'
        name='map overview'
        active={false}
        onClick={() => history.push(routeMaps.administrationOverview)}
      />}
      {currentTenant && <Menu.Item
        className='menu-item'
        name='project overview'
        active={false}
        onClick={() => history.push(routeMaps.administrationProjectOverview)}
      />}
      <Menu.Item
        className='menu-item'
        name='active projects'
        active={false}
        onClick={() => {
          setAdministrationInactiveProjectsPageActive(false);
          setAdministrationActiveProjectsPageActive(true);
          history.push(routeMaps.administrationProjects);
        }}
      />
      <Menu.Item
        className='menu-item'
        name='inactive projects'
        active={false}
        onClick={() => {
          setAdministrationActiveProjectsPageActive(false);
          setAdministrationInactiveProjectsPageActive(true);
          history.push(routeMaps.administrationProjects);
        }}
      />
    </Menu.Menu>
  </Menu.Item>
)

const AdministrationOverview = ({
  user,
  currentTenant,
  tenants,
  getTenantsDropdownOptions,
  onTenantChange,
  setOpenTenantDropdown
}) => (
  <div style={{ paddingBottom: 5 }}>
    {user && isEnabled(user.userRoles, [ROLES.SystemAdmin]) ? (
      <Menu.Menu>
        <Menu.Item
          className='menu-item'
          name=''
          active={false}
          onClick={() => { }}
        >
          {tenants && tenants.length > 0 ? (
            <Dropdown
              trigger={tenantDropdownTrigger(currentTenant && currentTenant.tenantName ? currentTenant.tenantName : '')}
              scrolling
              options={getTenantsDropdownOptions(tenants)}
              onChange={onTenantChange}
              selectOnBlur={false}
              onOpen={() => setOpenTenantDropdown(true)}
              onClose={() => setOpenTenantDropdown(false)} />
          ) : ''}
        </Menu.Item>
      </Menu.Menu>
    ) : null}
  </div>
)

const AdministrationReports = ({ history, currentTenant }) => (
  <React.Fragment>
    {currentTenant ? (
      <Menu.Item>
        <Menu.Header className='menu-header'>
          <Icon inverted name='file text' /> Reports
        </Menu.Header>
        <Menu.Menu>
          <Menu.Item
            className='menu-item'
            name='technician report'
            active={false}
            onClick={() => history.push(routeMaps.administrationTechnicianReport)}
          />
          <Menu.Item
            className='menu-item'
            name='assignments report'
            active={false}
            onClick={() => history.push(routeMaps.administrationAssignmentsReport)}
          />
          <Menu.Item
            className='menu-item'
            name='payroll report'
            active={false}
            onClick={() => history.push(routeMaps.administrationPayrollReport)}
          />
        </Menu.Menu>
      </Menu.Item>
    ) : null}
  </React.Fragment>
)

const People = ({ user, currentProject, history }) => {
  const isUserAuthorized = user && user.userRoles && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]);
  const isQuickSightManagementEnabled = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qsManagement;
  return (
    <React.Fragment>
      {isUserAuthorized && isQuickSightManagementEnabled ? (
        <Menu.Item>
          <Menu.Header className='menu-header'><Icon inverted name='user outline' /> People</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              className='menu-item'
              name='email reporting'
              active={false}
              onClick={() => history.push(routeMaps.peopleEmailReporting)}
            />
          </Menu.Menu>
        </Menu.Item>) : null}
    </React.Fragment>
  )
}

class CustomMenu extends Component {

  static contextType = AppContext;
  projects = [];
  tenants = [];

  state = {
    openProjectDropdown: false,
    openTenantDropdown: false,
  }

  setOpenProjectDropdown = (openProjectDropdown) => {
    this.setState({ openProjectDropdown });
  }

  setOpenTenantDropdown = (openTenantDropdown) => {
    this.setState({ openTenantDropdown });
  }

  logout = () => {
    const { history } = this.props;
    const { setProject, setUser, setTenantProjects, setTenant } = this.context;
    logout();
    setProject(null);
    setUser(null);
    setTenant(null);
    setTenantProjects([]);
    history.push(routeMaps.login);
  }

  getTenantsDropdownOptions = (tenants) => {
    this.tenants = tenants;
    let options = [];
    options = tenants.map((tenant) => {
      return { key: tenant.id, value: tenant.id, text: tenant.tenantName };
    });
    options.unshift({ key: 0, value: '', text: 'Select Tenant...', disabled: true });
    return options;
  }

  getProjectsDropdownOptions = (projects) => {
    this.projects = projects;
    let options = [];
    options = projects.map((project) => {
      return { key: project.id, value: project.id, text: project.projectName };
    });
    if (options.length === 1 && this.context.currentProject === null) {
      this.onProjectChange(null, { value: options[0].value });
    }
    options.unshift({ key: 0, value: '', text: 'Select Project...', disabled: true });
    return options;
  }

  onProjectChange = (event, data) => {
    const { getBlackOuts } = this.context;
    this.projects.forEach(async (project) => {
      if (project.id === data.value) {
        const { history } = this.props;
        const {
          projectTimeZone = null,
          projectHasBlackOuts,
          projectWorkOrderType,
          projectConfiguration,
          projectSequence,
          projectMobileTemplates,
          projectImportConfig,
          isDemo,
          latitude,
          longitude
        } = await getProjectByProjectId(project.id);
        project['projectTimeZone'] = projectTimeZone;
        project['projectHasBlackOuts'] = projectHasBlackOuts;
        project['projectWorkOrderType'] = projectWorkOrderType;
        project['isDemo'] = isDemo;
        project['projectConfiguration'] = JSON.parse(projectConfiguration);
        project['projectMobileTemplates'] = projectMobileTemplates;
        project['projectImportConfig'] = JSON.parse(projectImportConfig);
        project['projectSequence'] = JSON.parse(projectSequence);
        project['latitude'] = latitude;
        project['longitude'] = longitude;
        this.context.setProject(project);
        this.context.getDashboardRoutesData();
        this.context.getDashboardInventoryData();
        this.context.getDashboardProductionStatusData();
        this.context.getActivePlan(project);
        this.context.updateInvalidLocationCount();
        this.getWorkOrdersStatusCounts(project);
        const showSiteTestDashboard = project && project.projectConfiguration && project.projectConfiguration.additionalFeatures && project.projectConfiguration.additionalFeatures.showSiteTestDashboard ? project.projectConfiguration.additionalFeatures.showSiteTestDashboard : false;
        if (showSiteTestDashboard) {
          this.getMeterTestResults(project);
          this.getSiteTestResults(project);
        }
        const showSiteTestDashboardV2 = project && project.projectConfiguration && project.projectConfiguration.additionalFeatures && project.projectConfiguration.additionalFeatures.showSiteTestDashboardV2 ? project.projectConfiguration.additionalFeatures.showSiteTestDashboardV2 : false;
        if (showSiteTestDashboardV2) {
          this.getMeterTestResults(project);
          this.getSiteTestResults(project, 'v2');
        }
        this.getProjectUserByWorkOrderStatusAndCount(project);
        getBlackOuts(project);
        history.push(routeMaps.dashboard);
      }
    });
  }

  onTenantChange = (event, data) => {
    const { setTenant } = this.context;
    this.tenants.forEach(async (tenant) => {
      if (tenant.id === data.value) {
        const { history } = this.props;
        setTenant(tenant);
        history.push(routeMaps.administrationProjects);
      }
    });
  }

  getProjectUserByWorkOrderStatusAndCount = async (project) => {
    const { setProjectUserByWorkOrderStatusAndCount, setLoadingDashboardAssigments } = this.context;
    setLoadingDashboardAssigments(true);
    const projectUserByWorkOrderStatusAndCount = await listProjectUserByWorkOrderStatusAndCount(project.id);
    setProjectUserByWorkOrderStatusAndCount(projectUserByWorkOrderStatusAndCount);
    setLoadingDashboardAssigments(false);
  }

  getMeterTestResults = async (project) => {
    const { setMeterTestResults } = this.context;
    setMeterTestResults([]);
    const data = await workOrderService.getMeterTestResults(project);
    setMeterTestResults(data.getMeterTestResults);
  }

  getSiteTestResults = async (project, version) => {
    const { setSiteTestResults } = this.context;
    setSiteTestResults([]);
    const data = await workOrderService.getSiteTestResults(project, version);
    setSiteTestResults(data.getSiteTestResults);
  }

  getWorkOrdersStatusCounts = async (project) => {
    const { setWorkOrderStatusCounts } = this.context;
    setWorkOrderStatusCounts([]);
    const data = await workOrderService.getWorkOrdersStatusCounts(project);
    setWorkOrderStatusCounts(data.listWorkOrderStatusCounts);
  }

  render() {
    const { history } = this.props;
    const {
      user,
      currentProject,
      setWorkOrdersPageListViewActive,
      setWorkOrdersPageMapViewActive,
      tenants,
      currentTenant,
      showTermsOfUseModal,
      showPrivacyPolicyModal,
      setShowTermsOfUseModal,
      setShowPrivacyPolicyModal,
      setAdministrationActiveProjectsPageActive,
      setAdministrationInactiveProjectsPageActive,
    } = this.context;
    const { openProjectDropdown, openTenantDropdown } = this.state;
    const hasServiceType = currentProject && (currentProject.id === '03cb1e60-82ca-11eb-a9b6-55b04578f029');
    return (
      <div className='side-menu' style={openProjectDropdown || openTenantDropdown ? { overflowY: 'unset' } : { overflowY: 'auto' }}>
        <TermsOfUseModal showModal={showTermsOfUseModal} closeModal={() => setShowTermsOfUseModal(false)} />
        <PrivacyPolicyModal showModal={showPrivacyPolicyModal} closeModal={() => setShowPrivacyPolicyModal(false)} />
        <Menu inverted vertical style={{ background: 'rgba(49, 49, 49, 0)' }}>
          <Logo />
          {history.location.pathname.indexOf('administration') > -1 ? (
            <React.Fragment>
              <AdministrationOverview
                setOpenTenantDropdown={this.setOpenTenantDropdown}
                onTenantChange={this.onTenantChange}
                user={user}
                currentTenant={currentTenant}
                tenants={tenants}
                getTenantsDropdownOptions={this.getTenantsDropdownOptions}
              />
              <AdministrationProjects
                currentTenant={currentTenant}
                history={history}
                setAdministrationActiveProjectsPageActive={setAdministrationActiveProjectsPageActive}
                setAdministrationInactiveProjectsPageActive={setAdministrationInactiveProjectsPageActive} />
              <AdministrationReports currentTenant={currentTenant} history={history} />
              {currentTenant && <AdministrationPeople currentTenant={currentTenant} user={user} history={history} />}
            </React.Fragment>
          ) : (
            <React.Fragment> 
              <CSRPortal user={user} history={history} />
              <Account user={user} history={history} />
              <Project openProjectDropdown={openProjectDropdown} setOpenProjectDropdown={this.setOpenProjectDropdown} history={history} getProjectsDropdownOptions={this.getProjectsDropdownOptions} onProjectChange={this.onProjectChange} />
              {hasServiceType && <ProjectTools history={history} currentProject={currentProject} />}
              <Analysis user={user} currentProject={currentProject} history={history} />
              <WorkOrders setWorkOrdersPageListViewActive={setWorkOrdersPageListViewActive} setWorkOrdersPageMapViewActive={setWorkOrdersPageMapViewActive} currentProject={currentProject} history={history} />
              <Inventory user={user} currentProject={currentProject} history={history} />
              <QA user={user} currentProject={currentProject} history={history} />
              <Reports user={user} currentProject={currentProject} history={history} />
              <People user={user} currentProject={currentProject} history={history} />
            </React.Fragment>
          )}
          <General logout={this.logout} setShowTermsOfUseModal={setShowTermsOfUseModal} setShowPrivacyPolicyModal={setShowPrivacyPolicyModal} 
                user={user}
                currentTenant={currentTenant}
                history={history} />
        </Menu>
        <Version currentProject={currentProject} />
      </ div>
    );
  }
}

export default CustomMenu;
