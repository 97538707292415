import React, { Component } from 'react';
import AppContext from './app.context';
import * as uploadStatusService from '../services/uploadStatus.service';
import { useTimeZone } from '../../helpers/date';
import { getToken } from '../services/auth.service';
import jwt_decode from 'jwt-decode';
import { getUserByUsername, listTechniciansByInstallDateAndTenant } from '../services/user.service';
import { listProjectsByTenant, listAllActiveProjectLocationsByTenant } from '../services/project.service';
import * as workOrderService from '../services/workOrder.service';
import { isWaterProject } from '../../helpers/workOrderType';
import { getBlackOuts } from '../services/blackout.service';
import * as inventoryService from '../services/inventory.service';
import { getActivePlan } from '../services/plan.service';
import { getLatestVersionByType, createVersion } from '../services/version.service';
import config from '../../config';
import * as version from '../../helpers/version';

const initState = {
  plan: null,
  currentProject: null,
  user: null,
  currentTenant: null,
  tenants: [],
  workOrderStatusCounts: undefined,
  workOrderInvalidLocationCount: 0,
  showSideMenu: true,
  techniciansAssignmentsPerDay: [],
  inventoryStatusByMeterType: [],
  inventoryNonSerializedAssets: [],
  inventoryStatusByRegisterType: [],
  inventoryStatusByRadioType: [],
  showBlackOutAssignments: false,
  showInactiveTechnicians: false,
  showMapFilter: false,
  workOrdersMapCount: 0,
  uploadStatuses: [],
  projectUserByWorkOrderStatusAndCount: [],
  workOrdersPageMapViewActive: false,
  workOrdersPageListViewActive: false,
  tenantProjects: [],
  dailyProductionStatusColumnsByMeterType: [],
  dailyProductionStatusRowsByMeterType: [],
  dailyProductionStatusHasMeter: false,
  dailyProductionStatusColumnsByRegisterType: [],
  dailyProductionStatusRowsByRegisterType: [],
  dailyProductionStatusHasRegister: false,
  dailyProductionStatusColumnsByRadioType: [],
  dailyProductionStatusRowsByRadioType: [],
  dailyProductionStatusHasRadio: false,
  dailyProductionStatusByNonSerializedInventory: null,
  weeklyProductionStatus: [],
  weeklyProductionStatusWeek: 0,
  workOrderStatusByRoute: [],
  meterSizeByRouteMeterColumns: [],
  meterSizeByRouteRegisterColumns: [],
  meterSizeByRouteRows: [],
  showPrivacyPolicyModal: false,
  showTermsOfUseModal: false,
  loadingDashboardAssigments: false,
  loadingDashboardInventory: false,
  loadingDashboardBlackOuts: false,
  loadingDashboardRoutes: false,
  loadingDashboard: false,
  loadingTenantProjects: false,
  meterSizeByRouteHasMeter: false,
  meterSizeByRouteHasRegister: false,
  meterSizeByRouteHasRadio: false,
  administrationActiveProjectsPageActive: true,
  administrationInactiveProjectsPageActive: false,
  blackouts: [],
  administrationOverviewProjects: [],
  administrationOverviewTechnicians: [],
  administrationOverviewLoading: false,
  siteTestResults: null,
  meterTestResults: null,
  inventoryStatusDataByAssetLocation: {},
  assetLocations: [],
  showEditBlackoutsModal: false,
};

class GlobalState extends Component {
  state = { ...initState };

  initGlobalState = () => {
    this.setState({ ...initState });
  }

  setPlan = (plan) => {
    this.setState({ plan });
  }

  setBlackOuts = (blackouts) => {
    this.setState({ blackouts });
  }

  setShowEditBlackoutsModal = (showEditBlackoutsModal) => {
    this.setState({ showEditBlackoutsModal });
  }

  setShowPrivacyPolicyModal = (showPrivacyPolicyModal) => {
    this.setState({ showPrivacyPolicyModal });
  }

  setShowTermsOfUseModal = (showTermsOfUseModal) => {
    this.setState({ showTermsOfUseModal });
  }

  setProjectUserByWorkOrderStatusAndCount = (projectUserByWorkOrderStatusAndCount) => {
    this.setState({ projectUserByWorkOrderStatusAndCount });
  }

  setWorkOrdersMapCount = (workOrdersMapCount) => {
    this.setState({ workOrdersMapCount });
  }

  setShowMapFilter = (showMapFilter) => {
    this.setState({ showMapFilter });
  }

  listUploadStatusesByProject = async () => {
    const { currentProject } = this.state;
    if (currentProject) {
      let uploadStatuses = await uploadStatusService.listUploadStatusesByProject(currentProject.id);
      uploadStatuses = useTimeZone(uploadStatuses, ['createdDate'], currentProject.projectTimeZone);
      this.setState({ uploadStatuses });
    }
  }

  setTechniciansAssignmentsPerDay = (techniciansAssignmentsPerDay) => {
    this.setState({ techniciansAssignmentsPerDay });
  }

  setProject = async (currentProject) => {
    await this.setState({
      currentProject,
      showBlackOutAssignments: false,
      showInactiveTechnicians: false,
      dailyProductionStatusColumns: [],
      dailyProductionStatusRows: [],
      weeklyProductionStatus: [],
      weeklyProductionStatusWeek: 0,
      workOrderStatusByRoute: [],
      meterSizeByRouteRows: [],
      meterSizeByRouteMeterColumns: [],
      meterSizeByRouteRegisterColumns: [],
      dailyProductionStatusRowsByMeterType: [],
      dailyProductionStatusRowsByRegisterType: [],
      dailyProductionStatusRowsByRadioType: [],
      inventoryNonSerializedAssets: [],
      dailyProductionStatusByNonSerializedInventory: null,
      inventoryStatusByMeterType: [],
      inventoryStatusByRegisterType: [],
      inventoryStatusByRadioType: [],
      uploadStatuses: [],
      plan: null,
      workOrderStatusCounts: undefined,
      workOrderInvalidLocationCount: 0,
      inventoryStatusDataByAssetLocation: {},
      assetLocations: []
    });
  }

  setTenant = async (currentTenant) => {
    this.setState({ currentTenant, tenantProjects: [], loadingTenantProjects: true });
    if (currentTenant) {
      const myTenantProjects = await listProjectsByTenant(currentTenant.id,true);
      this.setTenantProjects(myTenantProjects);
    }
  }

  setTenants = (tenants) => {
    this.setState({ tenants });
  }

  setUser = (user) => {
    this.setState({ user });
  }

  setWorkOrderStatusCounts = (workOrderStatusCounts) => {
    this.setState({ workOrderStatusCounts });
  }

  setSiteTestResults = (siteTestResults) => {
    this.setState({ siteTestResults });
  }

  setMeterTestResults = (meterTestResults) => {
    this.setState({ meterTestResults });
  }

  updateInvalidLocationCount = async () => {
    this.setState({ workOrderInvalidLocationCount: 0 });
    const workOrderInvalidLocationCount = await workOrderService.getWorkOrderInvalidLocationCount(this.state.currentProject);
    this.setState({ workOrderInvalidLocationCount });
  }

  toggleSideMenu = async () => {
    await this.setState({ showSideMenu: !this.state.showSideMenu });
  }

  setShowBlackOutAssignments = (showBlackOutAssignments) => {
    this.setState({ showBlackOutAssignments });
  }

  setShowInactiveTechnicians = (showInactiveTechnicians) => {
    this.setState({ showInactiveTechnicians });
  }

  setWorkOrdersPageMapViewActive = (workOrdersPageMapViewActive) => {
    this.setState({ workOrdersPageMapViewActive });
  }

  setWorkOrdersPageListViewActive = (workOrdersPageListViewActive) => {
    this.setState({ workOrdersPageListViewActive });
  }

  setAdministrationActiveProjectsPageActive = (administrationActiveProjectsPageActive) => {
    this.setState({ administrationActiveProjectsPageActive });
  }

  setAdministrationInactiveProjectsPageActive = async (administrationInactiveProjectsPageActive) => {
    if (this.state.currentTenant) {
      const inactiveProjects = await listProjectsByTenant(this.state.currentTenant.id,false); 
      this.setState({ administrationInactiveProjectsPageActive, tenantProjects: inactiveProjects });
    }
  }

  initUser = async () => {
    const token = await getToken();
    if (token) {
      const decodedToken = jwt_decode(token);
      const userName = decodedToken['cognito:username'];
      const userGroups = decodedToken['cognito:groups'];
      const data = await getUserByUsername(userName);
      const user = { ...data, userGroups }
      this.setUser(user);

      // update version
      const oldVersion = await getLatestVersionByType();
      if (version.isNewerVersion(oldVersion.versionName, config.version)) {
        console.log('New version is available!')
        createVersion(config.version);
      }
      return user;
    } else {
      console.log('Unable to init the user');
    }
  }

  setTenantProjects = (tenantProjects) => {
    this.setState({ tenantProjects, loadingTenantProjects: false });
  }

  getDashboardInventoryData = async () => {
    const { currentProject } = this.state;
    if (currentProject) {
      this.setState({ loadingDashboardInventory: true });
      const newState = {};
      const inventoryDashboardShowSplitLocations = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowSplitLocations ? currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowSplitLocations : false;
      const inventoryDashboardShowWithDeleted = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowWithDeleted ? currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowWithDeleted : false;
      const inventoryDashboardShowWithDualCounts = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowWithDualCounts ? currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowWithDualCounts : false;
      let inventoryStatusByMeterType;
      if (inventoryDashboardShowSplitLocations) {
        const assetLocations = await inventoryService.listInventoryAssetLocation(currentProject.id);
        newState.assetLocations = assetLocations;
        const inventoryStatusDataByAssetLocation = {};
        for (let assetLocation of assetLocations) {
          inventoryStatusDataByAssetLocation[assetLocation] = {};
          if (!inventoryStatusByMeterType) {
            if (inventoryDashboardShowWithDeleted) { 
              inventoryStatusByMeterType = await inventoryService.getInventoryStatusByMeterTypeWithDeleted(currentProject.id, assetLocation);
            } else {
              inventoryStatusByMeterType = await inventoryService.getInventoryStatusByMeterType(currentProject.id, assetLocation);
            }
            inventoryStatusByMeterType.map(item => {
              item.assets = {
                [assetLocation]: {
                  totalAssetsAvailable: item.totalAssetsAvailable,
                  remainingAssetsAvailable: item.remainingAssetsAvailable
                }
              };
              delete item['totalAssetsAvailable'];
              delete item['remainingAssetsAvailable'];
              return item;
            });
          } else {
            const inventoryStatusByMeterTypeTemp = await inventoryService.getInventoryStatusByMeterType(currentProject.id, assetLocation);
            for (const [index, item] of inventoryStatusByMeterTypeTemp.entries()) {
              inventoryStatusByMeterType[index].assets[assetLocation] = {
                totalAssetsAvailable: item.totalAssetsAvailable,
                remainingAssetsAvailable: item.remainingAssetsAvailable
              };
            }
          }
          inventoryStatusDataByAssetLocation[assetLocation].inventoryStatusByRegisterType = await inventoryService.getInventoryStatusByRegisterType(currentProject.id, assetLocation);
          inventoryStatusDataByAssetLocation[assetLocation].inventoryStatusByRadioType = await inventoryService.getInventoryStatusByRadioType(currentProject.id, assetLocation);
        }
        newState.inventoryStatusByMeterType = inventoryStatusByMeterType;
        newState.inventoryStatusDataByAssetLocation = inventoryStatusDataByAssetLocation;
      } else {
        if (inventoryDashboardShowWithDeleted) { 
          inventoryStatusByMeterType = await inventoryService.getInventoryStatusByMeterTypeWithDeleted(currentProject.id);
        } else {
          inventoryStatusByMeterType = await inventoryService.getInventoryStatusByMeterType(currentProject.id);
        }
        const inventoryStatusByRegisterType = await inventoryService.getInventoryStatusByRegisterType(currentProject.id);
        let inventoryStatusByRadioType;
        if (inventoryDashboardShowWithDualCounts) {  
          inventoryStatusByRadioType = await inventoryService.getInventoryStatusByRadioTypeWithDual(currentProject.id);
        } else {
          inventoryStatusByRadioType = await inventoryService.getInventoryStatusByRadioType(currentProject.id);
        }
        newState.inventoryStatusByMeterType = inventoryStatusByMeterType;
        newState.inventoryStatusByRegisterType = inventoryStatusByRegisterType;
        newState.inventoryStatusByRadioType = inventoryStatusByRadioType;
      }
      const nonSerializedInventory = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory ? currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory : false;
      if (nonSerializedInventory) {
        const inventoryNonSerializedAssets = await inventoryService.listInventoryNonSerializedAssets(currentProject.id);
        newState.inventoryNonSerializedAssets = inventoryNonSerializedAssets;
      }
      newState.loadingDashboardInventory = false;
      this.setState(newState);
    }
  }

  setWeeklyProductionStatusWeek = async (week) => {
    const { currentProject } = this.state;
    this.setState({ loadingDashboard: true, weeklyProductionStatus: [] });
    const weeklyProductionStatus = await workOrderService.listWeeklyProductionStatus(currentProject, week);
    this.setState({ loadingDashboard: false, weeklyProductionStatusWeek: week, weeklyProductionStatus });
  }

  getDashboardProductionStatusData = async () => {
    const { currentProject, weeklyProductionStatusWeek } = this.state;
    this.setState({ loadingDashboard: true })
    if (currentProject) {
      let newState = {};
      const weeklyProductionStatus = await workOrderService.listWeeklyProductionStatus(currentProject, weeklyProductionStatusWeek);
      newState = { weeklyProductionStatus };
      if (currentProject && currentProject.projectWorkOrderType && isWaterProject(currentProject.projectWorkOrderType)) {
        const [dailyProductionStatusColumnsByMeterType, dailyProductionStatusRowsByMeterType, dailyProductionStatusHasMeter] = await workOrderService.listDailyProductionStatusByMeterType(currentProject);
        const [dailyProductionStatusColumnsByRegisterType, dailyProductionStatusRowsByRegisterType, dailyProductionStatusHasRegister] = await workOrderService.listDailyProductionStatusByRegisterType(currentProject);
        const [dailyProductionStatusColumnsByRadioType, dailyProductionStatusRowsByRadioType, dailyProductionStatusHasRadio] = await workOrderService.listDailyProductionStatusByRadioType(currentProject);
        newState = {
          ...newState,
          dailyProductionStatusColumnsByMeterType, dailyProductionStatusRowsByMeterType, dailyProductionStatusHasMeter,
          dailyProductionStatusColumnsByRegisterType, dailyProductionStatusRowsByRegisterType, dailyProductionStatusHasRegister,
          dailyProductionStatusColumnsByRadioType, dailyProductionStatusRowsByRadioType, dailyProductionStatusHasRadio,
        };

        const nonSerializedInventory = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory ? currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory : false;
        if (nonSerializedInventory) {
          const dailyProductionStatusByNonSerializedInventory = await workOrderService.listDailyProductionStatusByNonSerializedInventory(currentProject);
          newState = {
            ...newState,
            dailyProductionStatusByNonSerializedInventory
          }
        }
      }
      this.setState({ ...newState, loadingDashboard: false });
    }
  }

  getDashboardRoutesData = async () => {
    this.setState({ loadingDashboardRoutes: true });
    const { currentProject } = this.state;
    if (currentProject && currentProject.projectWorkOrderType && isWaterProject(currentProject.projectWorkOrderType)) {
      const workOrderStatusByRoute = await workOrderService.listWorkOrderStatusByRoute(currentProject);
      const [meterSizeByRouteMeterColumns, meterSizeByRouteRegisterColumns, meterSizeByRouteRows, meterSizeByRouteHasMeter, meterSizeByRouteHasRegister, meterSizeByRouteHasRadio] = await workOrderService.listMeterSizeByRoute(currentProject);
      this.setState({ workOrderStatusByRoute, meterSizeByRouteMeterColumns, meterSizeByRouteRegisterColumns, meterSizeByRouteRows, meterSizeByRouteHasMeter, meterSizeByRouteHasRegister, meterSizeByRouteHasRadio });
    }
    this.setState({ loadingDashboardRoutes: false });
  }

  setLoadingDashboardRoutes = (loadingDashboardRoutes) => {
    this.setState({ loadingDashboardRoutes });
  }

  setLoadingDashboardAssigments = (loadingDashboardAssigments) => {
    this.setState({ loadingDashboardAssigments });
  }

  setLoadingDashboardInventory = (loadingDashboardInventory) => {
    this.setState({ loadingDashboardInventory });
  }

  getBlackOuts = async (currentProject) => {
    this.setState({ loadingDashboardBlackOuts: true })
    if (currentProject && currentProject.projectHasBlackOuts) {
      const blackouts = await getBlackOuts(currentProject.id);
      this.setState({ blackouts, loadingDashboardBlackOuts: false });
    }
  }

  setCurrentProjectSequence = (projectSequence) => {
    // eslint-disable-next-line
    this.state.currentProject.projectSequence = projectSequence;
  }

  getActivePlan = async (currentProject) => {
    if (currentProject) {
      const plan = await getActivePlan(currentProject.id);
      this.setState({ plan });
    }
  }

  initAdministrationOverview = async (currentTenant) => {
    this.setState({ administrationOverviewLoading: true });
    const administrationOverviewTechnicians = await listTechniciansByInstallDateAndTenant(currentTenant.id);
    const administrationOverviewProjects = await listAllActiveProjectLocationsByTenant(currentTenant.id);
    this.setState({ administrationOverviewLoading: false, administrationOverviewTechnicians, administrationOverviewProjects });
  }

  setAdministrationOverviewLoading = (administrationOverviewLoading) => {
    this.setState({ administrationOverviewLoading });
  }
  
  render() {
    return (
      <AppContext.Provider value={{
        ...this.state,
        initAdministrationOverview: this.initAdministrationOverview,
        setAdministrationOverviewLoading: this.setAdministrationOverviewLoading,
        setProjectUserByWorkOrderStatusAndCount: this.setProjectUserByWorkOrderStatusAndCount,
        setWorkOrdersMapCount: this.setWorkOrdersMapCount,
        setShowMapFilter: this.setShowMapFilter,
        listUploadStatusesByProject: this.listUploadStatusesByProject,
        setTechniciansAssignmentsPerDay: this.setTechniciansAssignmentsPerDay,
        setProject: this.setProject,
        setUser: this.setUser,
        setSiteTestResults: this.setSiteTestResults,
        setMeterTestResults: this.setMeterTestResults,
        setWorkOrderStatusCounts: this.setWorkOrderStatusCounts,
        updateInvalidLocationCount: this.updateInvalidLocationCount,
        toggleSideMenu: this.toggleSideMenu,
        initGlobalState: this.initGlobalState,
        setShowBlackOutAssignments: this.setShowBlackOutAssignments,
        setShowInactiveTechnicians: this.setShowInactiveTechnicians,
        setWorkOrdersPageMapViewActive: this.setWorkOrdersPageMapViewActive,
        setWorkOrdersPageListViewActive: this.setWorkOrdersPageListViewActive,
        initUser: this.initUser,
        setTenant: this.setTenant,
        setTenants: this.setTenants,
        setTenantProjects: this.setTenantProjects,
        setShowPrivacyPolicyModal: this.setShowPrivacyPolicyModal,
        setShowTermsOfUseModal: this.setShowTermsOfUseModal,
        setLoadingDashboardAssigments: this.setLoadingDashboardAssigments,
        setLoadingDashboardInventory: this.setLoadingDashboardInventory,
        setLoadingDashboardRoutes: this.setLoadingDashboardRoutes,
        getBlackOuts: this.getBlackOuts,
        getDashboardRoutesData: this.getDashboardRoutesData,
        setCurrentProjectSequence: this.setCurrentProjectSequence,
        getDashboardInventoryData: this.getDashboardInventoryData,
        getDashboardProductionStatusData: this.getDashboardProductionStatusData,
        setPlan: this.setPlan,
        getActivePlan: this.getActivePlan,
        setAdministrationActiveProjectsPageActive: this.setAdministrationActiveProjectsPageActive,
        setAdministrationInactiveProjectsPageActive: this.setAdministrationInactiveProjectsPageActive,
        setWeeklyProductionStatusWeek: this.setWeeklyProductionStatusWeek,
        setShowEditBlackoutsModal: this.setShowEditBlackoutsModal,
      }}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export default GlobalState;