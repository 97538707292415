import React from 'react';
import { Modal, Button, Dropdown, Input } from 'semantic-ui-react';

// Utility function to strip special characters, allowing underscores, hyphens, and periods
export const stripSpecialCharacters = (str) => {
  console.log('Original string:', str);
  const sanitizedStr = str.replace(/[^a-zA-Z0-9_.-]/g, '');
  console.log('Sanitized string:', sanitizedStr);
  return sanitizedStr;
};

const AddPhotoModal = ({
  showModal,
  closeModal,
  onSubmit,
  onPhotoTypeChange,
  photoType,
  fileInputRef,
  uploadingNewPhoto,
  selectedWorkOrder,
  showCustomInputText,
  onCustomTypeChange,
  customPhotoType
}) => (
    <Modal dimmer={{ style: {zIndex: 1002}}} size='tiny' open={showModal}>
      <Modal.Header>Add Photo</Modal.Header>
      <Modal.Content>
        <Dropdown
          options={[
            { key: 0, value: '', text: 'Select photo type...', disabled: true },
            { key: 1, value: 'Custom', text: 'Custom' },
            ...(selectedWorkOrder && selectedWorkOrder.photos && selectedWorkOrder.photos.length > 0 ? selectedWorkOrder.photos.map((photo, index) => {
              let type = photo.photoName.split('-')[photo.photoName.split('-').length - 1].split('.')[0];
              return {
                key: index + 2,
                value: type,
                text: type
              }
            }) : [])
          ]}
          onChange={onPhotoTypeChange}
          fluid
          placeholder={`Photo Type: ${photoType || ''}`}
          selection />
        {showCustomInputText && <Input value={customPhotoType} onChange={onCustomTypeChange} type='text' placeholder='Custom Type' style={{ marginTop: '10px', width: '100%' }} />}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative>Cancel</Button>
        <Button onClick={() => fileInputRef.current.click()} loading={uploadingNewPhoto} type='file' disabled={!photoType || (showCustomInputText && !customPhotoType)} positive icon='cloud upload' labelPosition='right' content='Upload' />
        <input accept="image/jpg" ref={fileInputRef} type='file' hidden onChange={async (event) => {
          const file = event.target.files[0];
          const sanitizedPhotoType = stripSpecialCharacters(photoType);
          const sanitizedFileName = stripSpecialCharacters(file.name);
          await onSubmit({ ...event, target: { ...event.target, files: [new File([file], sanitizedFileName)] } }, selectedWorkOrder.id, selectedWorkOrder.oldMeterNumber, sanitizedPhotoType);
        }} />
      </Modal.Actions>
    </Modal>
  )

export default AddPhotoModal;